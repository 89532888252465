import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
import { useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { Client, Transaction } from "../../elements/frontend/src/Store/api";
import {
  useGetTransactionByIdQuery,
  useUpdateTransactionMutation,
} from "../../elements/frontend/src/Store/api/transactions/transactionsApi";

import {
  DocumentType,
  TypeName,
  //DownloadDialogProps,
} from "../../elements/frontend/src/Store/api/documents/interfaces";
import { Card, DownloadFileCard, DocumentsTable } from "../../elements/frontend/src/components";
import { useClients, useDocuments } from "../../elements/frontend/src/hooks";
import { DashbordContext } from "../../views/Private/Home";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { FileUploadDialogMandatsvertrag } from "../../elements/frontend/src/components/FileUploadDialogMandatsvertrag";
import TConfig from "../../config";
import { Section } from "../../elements/frontend/src/components/Section/Section";
import { DetailsFormValues } from "../Details/interfaces";
//import { isAdvisorClient } from "../../utils/step.utils";
import { ProcessSteps } from "../../elements/frontend/src/Store/api/metadata/interfaces";
import { addCompletedStep, getNextStep, isAdvisorClient } from "../../utils/step.utils";
import { DocumentSignDialog } from "./DocumentSignDialog";
import { s3Upload } from "../../elements/frontend/src/common";
import { authenticateUserForDocuSign, useDocuSignAccessToken } from "../../api/docuSign";
import { SignDocumentCard } from "./SignDocumentCard";
import { Dialog, DialogTitle, Typography, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
//import { Button, useMediaQuery } from "@mui/material";

const companyRepresentitiveEmail = "hk@beteiligungsboerse.eu";

const ClientAgreement = () => {
  const [accessToken] = useDocuSignAccessToken();
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dashboardContext: any = useContext(DashbordContext);
  const theme = useTheme();
  const selectedClientId = currentUser.selectedClient.client_id;
  //const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const dispatch = useDispatch();
  const { t } = useTranslation(["company/common"]);
  const { getDocumentCompany } = useDocuments();
  const [updateTransaction] = useUpdateTransactionMutation();
  const { updateClient } = useClients();
  const clientId = currentUser.selectedClient.client_id;
  const methods = useForm<DetailsFormValues>({
    mode: "all",
    defaultValues: {
      client: currentUser.clients.find((client: Client) => client.client_id === clientId),
    },
  });
  const companyName = currentUser.customer.name;

  const { watch, /* handleSubmit, */ setValue } = methods;
  const transaction: Transaction = watch("transaction");

  const typeName: TypeName = {
    avv: "Auftragsverarbeitungsvertrag",
    nda_company: "Vertraulichkeitserklärung",
    contract_company: "Auftragsvereinbarung",
  };

  const [fileToDownload, setFileToDownload] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<DocumentType | null>(null);

  const [documentToSign, setDocumentToSign] = useState<string | null>(null);

  const transactionByIdQuery = useGetTransactionByIdQuery(
    {
      transactionId: currentUser.transactions.filter(
        (item: Transaction) => item.client_id === currentUser.selectedClient.client_id
      )[0]?.transaction_id,
    },
    { skip: !currentUser.transactions[0] }
  );
  const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);

  const handleCloseUploadDialog = () => {
    setIsFileUploadDialogOpen(false);
  };

  const stateMetadata = transactionByIdQuery.data?.metadata.find(
    (metadata) => metadata.scope === "state"
  )?.data;
  // @ts-ignore
  const custom_company_contract = stateMetadata?.custom_company_contract ?? false;
  const handleDownloadDocument = (type: DocumentType) => {
    setSelectedType(type);
    dispatch(
      setCurrentUser({
        loading: true,
      })
    );

    const params = {
      type: type,
      client_id: currentUser.selectedClient.client_id,
      transaction_id: currentUser.transactions[0].transaction_id,
      //custom_company_contract: custom_company_contract,
    };

    getDocumentCompany(params)
      .then((response) => {
        setFileToDownload(response.download_url);

        // update transaction state "contract_company_received" after downloading
        const transaction_id = dashboardContext.transactions[0].transaction_id;
        let objTransactionData = {};

        objTransactionData = {
          metadata: [
            {
              scope: "state",
              data: {
                contract_company_received: true,
              },
            },
          ],
        };

        updateTransaction({ transactionId: transaction_id, body: objTransactionData }).then(
          (responseTransaction) => {
            if (responseTransaction) {
              dispatch(
                setCurrentUser({
                  loading: false,
                })
              );
            }
          }
        );
      })
      .catch(() => {
        dispatch(
          setCurrentUser({
            loading: false,
          })
        );
      });
  };

  const handleSignDocument = (type: DocumentType) => {
    dispatch(
      setCurrentUser({
        loading: true,
      })
    );

    const params = {
      type: type,
      client_id: currentUser.selectedClient.client_id,
      transaction_id: currentUser.transactions[0].transaction_id,
      custom_company_contract: custom_company_contract,
    };

    getDocumentCompany(params)
      .then((response) => {
        setDocumentToSign(response.download_url);

        // update transaction state "contract_company_received" after downloading
        const transaction_id = dashboardContext.transactions[0].transaction_id;
        let objTransactionData = {};

        objTransactionData = {
          metadata: [
            {
              scope: "state",
              data: {
                contract_company_received: true,
              },
            },
          ],
        };

        updateTransaction({ transactionId: transaction_id, body: objTransactionData }).then(
          (responseTransaction) => {
            if (responseTransaction) {
              dispatch(
                setCurrentUser({
                  loading: false,
                })
              );
            }
          }
        );
      })
      .catch(() => {
        dispatch(
          setCurrentUser({
            loading: false,
          })
        );
      });
  };

  const handleClientAgreementUploaded = async (data: any) => {
    const nextStep = getNextStep(ProcessSteps.TRANSACTION_CHECK, isAdvisorClient(currentUser));

    const updatedClientData = {
      metadata: [
        {
          scope: "profile",
          data: {
            dashboard_currentStep: nextStep ? nextStep : ProcessSteps.CLIENT_AGREEMENT,
            dashboard_lastStep: ProcessSteps.CLIENT_AGREEMENT,
            dashboard_completedSteps: addCompletedStep(
              dashboardContext.dashboard_completedSteps,
              ProcessSteps.CLIENT_AGREEMENT
            ),
          },
        },
      ],
    };

    await updateClient(selectedClientId, updatedClientData);

    window.location.reload();
  };
  
  useEffect(() => {
    if (transactionByIdQuery.isSuccess) {
      setValue("transaction", transactionByIdQuery.data);
    }
  }, [setValue, transactionByIdQuery]);


  // @ts-ignore
  const docuSignEnvelopeId: string | null = stateMetadata?.contract_company_envelope_id ?? null;
  // @ts-ignore
  const isFinalized = stateMetadata?.is_finalized ?? false;
  //@ts-ignore
  
  
  const metadata = transaction?.metadata ? transaction.metadata : [];
  const secondaryScope = metadata?.find((item) => item.scope === "secondary");
  const accountEmail = transaction?.client_contact?.email;
  // @ts-ignore:next-line
  let secondaryEmails = secondaryScope?.data?.adresses?.map((address: any) => address.email) ?? [];
  const emails = [...new Set([...secondaryEmails, accountEmail, companyRepresentitiveEmail])];

  if(!stateMetadata) {
    return <div></div>
  }
  return (
    <Box component="form" sx={{ width: "100%", maxWidth: theme.breakpoints.values.xl }}>
      {/****** DOCUMENT DOWNLOAD ************/}
      <Section title={t("company/common:client_agreement.documents.client_agreement")}>
        <Card>
          <Stack direction="row" spacing={2}>
            <DownloadFileCard
              type={DocumentType.CONTRACT_COMPANY}
              fileName={t("company/common:client_agreement.documents.client_agreement")}
              handleDownloadDocument={handleDownloadDocument}
            />
            <SignDocumentCard
              fileName="Mandatsvetrag"
              onSignDocument={() => {
                if (!accessToken) {
                  authenticateUserForDocuSign();
                }
                handleSignDocument(DocumentType.CONTRACT_COMPANY);
              }}
            />
            <FileUploadDialogMandatsvertrag
              dialogOpen={isFileUploadDialogOpen}
              handleDialogClose={handleCloseUploadDialog}
              product={"company"}
              page={"client_agreement"}
              fileUrn={TConfig.file.URN.CONTRACT_COMPANY}
              handlePrevNewFileUpdate={handleClientAgreementUploaded}
              fileToEdit={undefined}
            />
          </Stack>
          <Dialog
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "70%",
                  maxWidth: "400px",
                  height: "300px",
                },
              },
            }}
            open={fileToDownload !== null}
            onClose={() => {
              setFileToDownload(null);
            }}
          >
            <DialogTitle sx={{ textAlign: "center" }}>
              {selectedType && (
                <>
                  <Typography variant="inherit" style={{ fontSize: 24, fontWeight: "bold" }}>
                    {companyName}
                  </Typography>
                  <Typography variant="inherit" style={{ fontSize: 16 }}>
                    {typeName[selectedType]}
                  </Typography>
                </>
              )}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                  fontSize: 20,
                }}
                id="alert-dialog-slide-description"
              >
                {t("company/common:secondary_download_dialog.dialog_content_text")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                // size={isMobile ? 'small' : 'medium'}
                sx={{
                  px: { sx: 2, md: 4 },
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                }}
                onClick={() => {
                  if (fileToDownload) {
                    window.open(fileToDownload, "_blank");
                    setFileToDownload(null);
                  }
                }}
              >
                Hereunterladen
              </Button>
            </DialogActions>
          </Dialog>
          {emails && emails.length > 0 && (
            <DocumentSignDialog
              isOpen={documentToSign !== null}
              fileUrl={documentToSign}
              envelopeId={docuSignEnvelopeId}
              header={companyName}
              isFinalized={isFinalized}
              onClose={() => {
                setDocumentToSign(null);
              }}
              onSign={() => {
                setDocumentToSign(null);
              }}
              emails={emails}
              onSendEnvelope={async (envelopeId) => {
                const transactionId = dashboardContext.transactions[0].transaction_id;
                const transactionUpdateData: Partial<Transaction> = {
                  metadata: [
                    {
                      scope: "state",
                      data: {
                        contract_company_envelope_id: envelopeId,
                        // @ts-ignore
                        is_finalized: false,
                      },
                    },
                  ],
                };

                await updateTransaction({
                  transactionId: transactionId,
                  body: transactionUpdateData,
                });
              }}
              onAllSigned={async (doc) => {
                const file = new File([doc.data], "Mandatsvetragunterschrieben.pdf", {
                  type: "application/pdf",
                });

                const transactionId = dashboardContext.transactions[0].transaction_id;
                const transactionUpdateData: Partial<Transaction> = {
                  metadata: [
                    {
                      scope: "state",
                      data: {
                        // @ts-ignore
                        is_finalized: true,
                      },
                    },
                  ],
                };

                await updateTransaction({
                  transactionId: transactionId,
                  body: transactionUpdateData,
                });

                await s3Upload(TConfig.file.URN.CONTRACT_COMPANY, file, [
                  {
                    urn: TConfig.record.URN.TRANSACTION,
                    value: transaction?.transaction_id,
                  },
                ]);
                await handleClientAgreementUploaded(doc);
              }}
            />
          )}
        </Card>
      </Section>
      <Section title={t("company/common:client_agreement.documents.document_list")}>
        <Card>
          {transaction?.files && (
            <DocumentsTable
              //@ts-ignore
              originalFiles={transaction?.files}
              product={"company"}
              page={"details"}
              hideButtons
            />
          )}
        </Card>
      </Section>
    </Box>
  );
};

export default ClientAgreement;
/* function updateClient(arg0: { clientId: any; body: Partial<Client> }) {
  throw new Error("Function not implemented.");
} */
