import { OnboardingFormValues, OnboardingSteps } from "./interfaces";
import { Box, useMediaQuery } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DashbordContext } from "../../views/Private/Home";
import { Documents } from "./components/Documents";
import { Company } from "./components/Company";
import { Contact } from "./components/Contact";
import { Profile } from "./components/Profile";
import { Interest } from "./components/Interest";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { OnboardingSchema } from "./Onboarding.schema";
import {
  Client,
  ProcessSteps,
  InvestStructure,
  StateMetadata,
  Transaction,
} from "../../elements/frontend/src/Store/api";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../elements/frontend/src/Store/hooks/useAppDispatch";
import { useUpdateTransactionMutation } from "../../elements/frontend/src/Store/api/transactions/transactionsApi";
import { useUpdateClientMutation } from "../../elements/frontend/src/Store/api/clients/clientsApi";
import parsePhoneNumber from "libphonenumber-js";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
import { addCompletedStep, getNextStep, isAdvisorClient } from "../../utils/step.utils";
import { DetailsInterestCard } from "./components/DetailsInterestCard";
const getInitialFormValues = (context: any): OnboardingFormValues => {
  const isCountry = context.address.country;
  const hasTradeRegistration = !!context.profile.trade_registration;
  let tradeRegistrationValue = context.profile.trade_registration;

  if (isCountry && !hasTradeRegistration) {
    tradeRegistrationValue = "HRB-";
  }

  return {
    currentStep: OnboardingSteps.COLLECT_DATA,
    avv_received: false,
    nda_received: false,
    company_name: context.company_name,
    postal: context.address.postal,
    street: context.address.street,
    city: context.address.city,
    country: context.address.country,

    first_name: context.contact.first_name,
    last_name: context.contact.last_name,
    telephone: context.contact.telephone,

    min_cap: context.interest.min_cap,
    max_cap: context.interest.max_cap,
    phase: context.interest.phase,
    self_ev: context.interest.self_ev,
    stake: context.interest.stake,
    type: context.interest.type,
    period: context.interest.period,
    structure: context.interest.structure,
    legal_form: context.profile.legal_form,
    fiscal_year: context.profile.fiscal_year,
    categories: context.profile.categories,
    subCategories: context.profile.sub_categories ? context.profile.sub_categories : [],
    website: context.profile.website,
    nace_code: context.profile.nace_code,
    trade_registration: tradeRegistrationValue,
    no_bankruptcy: context.profile.no_bankruptcy,
    no_project_business: context.profile.no_project_business,
  };
};

export const Onboarding = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["company/common"]);
  const theme = useTheme();
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const dashboardContext = useContext(DashbordContext);
  const transaction = dashboardContext.transactions[0] as Transaction;
  const hasAvvReceived = !!transaction?.state?.steps?.avv_received?.completed;
  const hasNdaReceived = !!transaction?.state?.steps?.nda_received?.completed;
  const transactionId = (dashboardContext.transactions[0] as Transaction).transaction_id;
  const [updateTransaction] = useUpdateTransactionMutation();
  const [updateClient] = useUpdateClientMutation();
  const [shouldRerender, setShouldRerender] = useState(false);

  const [initial, setInitial] = useState(true);

  const methods = useForm<OnboardingFormValues>({
    mode: "all",
    defaultValues: getInitialFormValues(dashboardContext),
    resolver: yupResolver(OnboardingSchema(t)),
  });
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const currentStep = watch("currentStep");
  const partialTransaction: Partial<Transaction> = {
    transaction_id: transactionId,
    invest: {
      min_cap: watch("min_cap"),
      max_cap: watch("max_cap"),
      phase: watch("phase"),
      self_ev: watch("self_ev"),
      stake: watch("stake"),
      type: watch("type"),
      period: watch("period"),
      structure: watch("structure"),
    },
  };

  const handleUpdateClientData = async (formValues: OnboardingFormValues) => {
    const {
      street,
      postal,
      city,
      country,
      first_name,
      last_name,
      telephone,
      legal_form,
      fiscal_year,
      trade_registration,
      no_bankruptcy,
      no_project_business,
      website,
      nace_code,
      categories,
      subCategories,
      min_cap,
      max_cap,
      phase,
      self_ev,
      stake,
      type,
      structure,
      period,
    } = formValues;
    const { currentStep } = formValues;

    if (currentStep === OnboardingSteps.COLLECT_DATA) {
      dispatch(
        setCurrentUser({
          loading: true,
        })
      );

      const formattedPhoneNumber = parsePhoneNumber(telephone)?.formatInternational();
      const clientData: Partial<Client> = {
        address: {
          line_1: street,
          postal,
          city,
          country,
        },
        contact: {
          first_name,
          last_name,
          telephone: formattedPhoneNumber ? formattedPhoneNumber : telephone,
        },
        metadata: [
          {
            scope: "profile",
            data: {
              legal_form,
              fiscal_year,
              trade_registration,
              no_bankruptcy,
              no_project_business,
              website,
              categories,
              sub_categories: subCategories,
              nace_code,
            },
          },
        ],
      };

      await updateClient({ clientId: currentUser.selectedClient.client_id, body: clientData });

      const transactionData: Partial<Transaction> = {
        invest: {
          min_cap,
          max_cap,
          phase,
          self_ev,
          stake,
          type,
          period,
          structure,
        },
        metadata: [
          {
            scope: "state",
            data: {
              invest_request: true,
            },
          },
        ],
      };

      await updateTransaction({
        transactionId: (dashboardContext.transactions[0] as Transaction).transaction_id,
        body: transactionData,
      });

      setValue("currentStep", OnboardingSteps.DOWNLOAD_DOCUMENTS);

      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }

    if (currentStep === OnboardingSteps.DOWNLOAD_DOCUMENTS) {
      dispatch(
        setCurrentUser({
          loading: true,
        })
      );

      const nextStep = getNextStep(ProcessSteps.ONBOARDING, isAdvisorClient(currentUser));

      const clientData: Partial<Client> = {
        metadata: [
          {
            scope: "profile",
            data: {
              dashboard_lastStep: nextStep,
              dashboard_currentStep: nextStep,
              dashboard_completedSteps: addCompletedStep(
                dashboardContext.dashboard_completedSteps,
                ProcessSteps.ONBOARDING
              ),
            },
          },
        ],
      };

      await updateClient({ clientId: currentUser.selectedClient.client_id, body: clientData });

      const stateMetadata: StateMetadata = {
        scope: "state",
        data: {
          avv_received: true,
          nda_received: true,
        },
      };

      const transactionPartial = {
        metadata: [stateMetadata],
      };

      await updateTransaction({ transactionId, body: transactionPartial });

      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }

    if (currentStep === OnboardingSteps.DOWNLOAD_DOCUMENTS) {
      window.location.reload();
    }
  };

  const handleTransactionDataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name as keyof OnboardingFormValues;
    const value = event.target.value;
    setValue(name, value);
  };

  const handleInvestorProfileAutoCompleteSelect = (
    event: React.ChangeEvent | React.SyntheticEvent<Element, Event>,
    value: unknown,
    field: string
  ) => {
    const formField = field as keyof OnboardingFormValues;
    if (formField === "type" || formField === "stake") {
      const typedValue = value as { value: string; summary: string }[];
      setValue(
        field as keyof OnboardingFormValues,
        typedValue.map((item) => item.value)
      );
    }

    if (formField === "period" || formField === "phase") {
      const typedValue = value as { value: string; summary: string } | null;
      setValue(formField, typedValue ? typedValue.value : "");
    }

    if (formField === "structure") {
      const typedValue = value as { cat: InvestStructure; summary: string } | null;
      setValue(formField, typedValue ? typedValue.cat : InvestStructure.Empty);
    }
  };

  const handelErrorFocus = (control: any): void => {
    setTimeout(() => {
      const componentName = Object.keys(control._formState.errors)[0];
      if (componentName) {
        const fieldElement = document.getElementsByName(componentName)[0];
        if (fieldElement) {
          fieldElement.focus();
        }
      }
    }, 20);
  };

  useEffect(() => {
    if (!shouldRerender) {
      const timeoutId = setTimeout(() => {
        setShouldRerender(true);
      }, 10);
      return () => clearTimeout(timeoutId);
    }
  }, [shouldRerender]);

  return (
    <Box
      component="form"
      sx={{ width: "100%", maxWidth: theme.breakpoints.values.xl }}
      onSubmit={handleSubmit(handleUpdateClientData)}
    >
      <FormProvider {...methods}>
        <Company />
        <Contact />
        <Profile initial={initial} setInitial={setInitial} />
        <DetailsInterestCard
          transaction={partialTransaction as Transaction}
          handleTransactionDataChange={handleTransactionDataChange}
          handleInvestorProfileAutoCompleteSelect={handleInvestorProfileAutoCompleteSelect}
          loading={false}
          formErrors={errors}
        />
        <Interest />
        <Documents />
        <Stack direction="row" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size={isMobile ? "small" : "medium"}
            sx={{
              px: { xs: 3, md: 6 },
              py: 1,
              mr: 1,
              mb: 5,
              mt: 2,
              fontWeight: "bold",
              color: theme.palette.primary.main,
              borderRadius: "4px",
            }}
            onClick={() => {
              handelErrorFocus(control);
              setInitial(false);
            }}
          >
            {currentStep === OnboardingSteps.COLLECT_DATA && !hasAvvReceived && !hasNdaReceived
              ? t("misc.update")
              : t("misc.next")}
          </Button>
        </Stack>
      </FormProvider>
    </Box>
    
  );
};
