export interface DocumentResponse {
  download_url: string;
  file_format: string;
  file_name: string;
  file_type: string;
}

export enum DocumentType {
  AVV = "avv",
  NDA_COMPANY = "nda_company",
  CONTRACT_COMPANY = "contract_company",
  CONTRACT_ADVISOR = "contract_advisor",
}

export interface DownloadDialogProps {
  fileToDownload: string | null;
  setFileToDownload: (file: string | null) => void;
  companyName: string;
  selectedType: string | null;
  typeName: Record<string, string>;
  isMobile: boolean;
  theme: any;
}

export interface TypeName {
  [key: string]: string;
}
