import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";

import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
import {
  Category,
  Client,
  InvestStructure,
  MetadataType,
  Ownership,
  ProfileMetadata,
  SusaMetadata,
  Transaction,
} from "../../elements/frontend/src/Store/api";

import { DetailsCompanyCard } from "../../elements/frontend/src/components/DetailsCompanyCard/DetailsCompanyCard";
import { DetailsContactCard } from "../../elements/frontend/src/components/DetailsContactCard/DetailsContactCard";
import {
  Card,
  DetailsCompanyInformationCard,
  DocumentsTable,
  DownloadFileCard,
  PrimaryButton,
} from "../../elements/frontend/src/components";
import {
  initialCustomersData,
  initialProductsData,
} from "../../elements/frontend/src/components/DetailsCompanyInformationCard/DetailsCompanyInformationCard.utils";
import { DetailsInterestCard } from "../../elements/frontend/src/components/DetailsInterestCard/DetailsInterestCard";
import { DetailsMarketAnalysisCard } from "../../elements/frontend/src/components/DetailsMarketAnalysisCard/index";
import { AdditionalCompanyInformation } from "../../elements/frontend/src/components/AdditionalCompanyInformation/AdditionalCompanyInformation";
import {
  useGetTransactionByIdQuery,
  useUpdateTransactionMutation,
} from "../../elements/frontend/src/Store/api/transactions/transactionsApi";
import { useForm } from "react-hook-form";
import { DetailsFormValues } from "./interfaces";
import { Section } from "../../elements/frontend/src/components/Section/Section";
import { DocumentType, TypeName } from "../../elements/frontend/src/Store/api/documents/interfaces";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material/Select";
import { parsePhoneNumber } from "libphonenumber-js";
import { CategoryType } from "../../elements/frontend/src/components/CategoriesDropdown/interfaces";
import { removeAllChildSubCategoriesWithoutSelectedRootCategory } from "../../elements/frontend/src/components/CategoriesDropdown/CategoriesDropdown.utils";
import { useGetCategoryTreesQuery } from "../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";
import { getValueFromMetadata } from "../../elements/frontend/src/common";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../elements/frontend/src/Store/hooks/useAppDispatch";
import { useDocuments } from "../../elements/frontend/src/hooks";
import { useUpdateClientMutation } from "../../elements/frontend/src/Store/api/clients/clientsApi";
import { DashboardDispatch, DashbordContext } from "../../views/Private/Home";

export const Details = () => {
  const dashboardContext: any = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);
  const dispatch = useAppDispatch();
  const { getDocumentCompany } = useDocuments();
  const { t } = useTranslation(["platform/common"]);
  const [updateTransaction] = useUpdateTransactionMutation();
  const [updateClient] = useUpdateClientMutation();
  const [isDirty, setIsDirty] = useState(false);

  const [initialLoad, setInitialLoad] = useState(true);

  const theme = useTheme();
  const currentUser: any = useAppSelector(({ currentUser }) => currentUser);
  const clientId = currentUser.selectedClient.client_id;
  const [shouldRerender, setShouldRerender] = useState(false);

  const methods = useForm<DetailsFormValues>({
    mode: "all",
    defaultValues: {
      client: currentUser.clients.find((client: Client) => client.client_id === clientId),
    },
  });
  const { watch, setValue, control } = methods;
  const clientData: Client = watch("client");
  const transaction: Transaction = watch("transaction");
  const profileMetadata = watch("client").metadata?.find(
    (data) => data.scope === "profile"
  ) as ProfileMetadata;
  const products = profileMetadata?.data?.products_data ?? initialProductsData;

  let customers = profileMetadata?.data?.customers_data;
  if (!customers || customers.length !== 3) {
    customers = initialCustomersData;
  }

  const susaData = watch("client").metadata?.find((item) => item.scope === "susa") as SusaMetadata;

  const categoryTreesQuery = useGetCategoryTreesQuery();
  const categoryTrees = categoryTreesQuery.data;
  const transactionByIdQuery = useGetTransactionByIdQuery(
    {
      transactionId: currentUser.transactions.filter(
        (item: Transaction) => item.client_id === currentUser.selectedClient.client_id
      )[0]?.transaction_id,
    },
    { skip: !currentUser.transactions[0] }
  );

  const subCategoryIds = useMemo(() => {
    const value = getValueFromMetadata("profile", "sub_categories", clientData?.metadata);
    if (!value) {
      return [];
    }
    return value;
  }, [clientData?.metadata]);

  const ContentRef = useRef();
  const CompanyRef = useRef();
  const ContactRef = useRef();
  const CompanyInformationRef = useRef();
  const MarketAnalysisRef = useRef();
  const DocumentDownloadRef = useRef();
  const DocumentsManagementRef = useRef();
  const AdditionalCompanyInformationRef = useRef();
  const DetailsInterestCardRef = useRef();

  useEffect(() => {
    if (dashboardContext.scrollTo) return;
    window.scrollTo(0, 0);
  }, [dashboardContext.scrollTo]);
  useEffect(() => {
    if (!dashboardContext.scrollTo) return;

    const refs = {
      ContentRef,
      CompanyRef,
      ContactRef,
      CompanyInformationRef,
      MarketAnalysisRef,
      DocumentDownloadRef,
      DocumentsManagementRef,
      AdditionalCompanyInformationRef,
      DetailsInterestCardRef,
    };

    // @ts-ignore
    const top = refs[dashboardContext.scrollTo]?.current?.offsetTop;
    const SCROLL_THRESHOLD = 135;

    // @ts-ignore
    const contentTop = ContentRef?.current?.scrollTop;
    if (contentTop !== undefined && top !== undefined) {
      if (contentTop + SCROLL_THRESHOLD === top) {
        window.scrollBy({
          top: -1 * SCROLL_THRESHOLD,
          behavior: "smooth",
        });
        setTimeout(() => {
          window.scrollTo({
            top: top - SCROLL_THRESHOLD,
            behavior: "smooth",
          });
          return;
        }, 100);
      }
    }
    if (top !== undefined) {
      ContentRef?.current &&
        window.scrollTo({
          top: top - SCROLL_THRESHOLD,
          behavior: "smooth",
        });
    }
  }, [dashboardContext.scrollTo]);

  const handleClientDataChange = (
    event:
      | React.ChangeEvent<{ value: string; name?: string | undefined }>
      | SelectChangeEvent<string>,
    field: string,
    isMetadata?: boolean
  ) => {
    const value = event.target.value;
    const name = event.target.name;

    if (!isMetadata && name === "name") {
      const valuePath: any = `client.${name}`;
      setValue(valuePath, value);
      return;
    }

    if (!isMetadata && name !== "name") {
      const valuePath: any = `client.${field}.${name}`;
      setValue(valuePath, value);
      return;
    }

    if (isMetadata) {
      const updatedMetadata = clientData.metadata?.map((item) => {
        if ((item.scope === "profile" || item.scope === "susa") && name) {
          return {
            ...item,
            data: {
              ...item.data,
              [name]: value,
            },
          } as ProfileMetadata | SusaMetadata;
        }
        return item;
      });

      setValue("client.metadata", updatedMetadata);
      setIsDirty(true);
      return;
    }
  };
  const handleTradeRegistrationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedMetadata = clientData.metadata?.map((item) => {
      if (item.scope === "profile") {
        return {
          ...item,
          data: {
            ...item.data,
            trade_registration: event.target.value,
          },
        } as ProfileMetadata;
      }
      return item;
    });

    setValue("client.metadata", updatedMetadata);
    setIsDirty(true);
  };
  const handlePhoneChange = (value: string) => {
    setValue("client.contact.telephone", value);
    setIsDirty(true);
  };

  const checkPhoneNumber = (value: string) => {
    try {
      const phone = parsePhoneNumber(value);

      return phone.isValid();
    } catch (e) {
      return false;
    }
  };
  const handleCategoriesSelect = (categories: Category[], type: CategoryType) => {
    const categoryIds = categories.map((category) => category.value);

    if (!clientData?.metadata) {
      const updatedMetadata: MetadataType[] = [
        ...clientData.metadata,
        { scope: "profile", data: { categories: [], sub_categories: [] } },
      ];
      setValue("client.metadata", updatedMetadata);
      setIsDirty(true);
    } else {
      const isScopeProfileExist = clientData?.metadata?.find((item) => item.scope === "profile");

      if (!isScopeProfileExist) {
        const updatedMetadata: MetadataType[] = [
          ...clientData.metadata,
          { scope: "profile", data: { categories: [], sub_categories: [] } },
        ];
        setValue("client.metadata", updatedMetadata);
        setIsDirty(true);
      }
    }

    if (type === CategoryType.CATEGORY) {
      const updatedMetadata = clientData.metadata?.map((item) => {
        if (item.scope === "profile") {
          return {
            ...item,
            data: {
              ...item.data,
              categories: categoryIds,
              sub_categories: categoryTrees
                ? removeAllChildSubCategoriesWithoutSelectedRootCategory(
                    categoryIds,
                    subCategoryIds,
                    categoryTrees
                  )
                : subCategoryIds,
            },
          } as ProfileMetadata;
        }
        return item;
      });

      setValue("client.metadata", updatedMetadata);
      setIsDirty(true);
    } else if (type === CategoryType.SUB_CATEGORY) {
      const updatedMetadata = clientData.metadata?.map((item) => {
        if (item.scope === "profile") {
          return {
            ...item,
            data: {
              ...item.data,
              sub_categories: categoryIds,
            },
          } as ProfileMetadata;
        }
        return item;
      });

      setValue("client.metadata", updatedMetadata);
      setIsDirty(true);
    }
  };

  const handleProductDataChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const name = e.target.name;
    const value = e.target.value.split(".").join("");

    const updatedProducts = products.map((product, i) => {
      if (i === index) {
        return {
          ...product,
          [name]: value,
        };
      }
      return product;
    });

    const updatedMetadata = clientData.metadata?.map((item) => {
      if (item.scope === "profile") {
        return {
          ...item,
          data: {
            ...item.data,
            products_data: updatedProducts,
          },
        } as ProfileMetadata;
      }
      return item;
    });

    setValue("client.metadata", updatedMetadata);
    setIsDirty(true);
  };

  const handleCustomersDataChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const name = e.target.name;
    const value = e.target.value.split(".").join("");

    const updatedCustomers = customers?.map((customer, i) => {
      if (i === index) {
        return {
          ...customer,
          [name]: value,
        };
      }
      return customer;
    });

    const updatedMetadata = clientData.metadata?.map((item) => {
      if (item.scope === "profile") {
        return {
          ...item,
          data: {
            ...item.data,
            customers_data: updatedCustomers,
          },
        } as ProfileMetadata;
      }
      return item;
    });

    setValue("client.metadata", updatedMetadata);
    setIsDirty(true);
  };

  const handleTransactionDataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    const value = event.target.value;
    const name = event.target.name;

    setValue(`transaction.${field}.${name}` as any, value);
    setIsDirty(true);
  };

  const handleInvestorProfileAutoCompleteSelect = (
    event: React.ChangeEvent | React.SyntheticEvent<Element, Event>,
    value: { value: string; summary: string }[] | { value: string; summary: string },
    field: string
  ) => {
    if ((value && field === "type") || field === "stake") {
      const newValue = (value as { value: string; summary: string }[]).map((item) => item.value);
      setValue(`transaction.invest.${field}`, newValue);
      setIsDirty(true);
    }

    if ((value && field === "phase") || field === "period") {
      const newValue = (value as { value: string; summary: string }).value;
      setValue(`transaction.invest.${field}`, newValue);
      setIsDirty(true);
    }

    if (field === "structure") {
      const newValue = (value as { value: string; summary: string; cat: InvestStructure }).cat;

      setValue(`transaction.invest.${field}`, newValue);
      setIsDirty(true);
    }
  };
  const handleOwnershipChange = (newOwnerships: Ownership[]) => {
    const eventData: any = { target: { name: "ownerships", value: newOwnerships } };
    handleSusaDataChange(eventData);
  };
  const handleSusaDataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = event.target.name;
    const value = event.target.value;

    const updatedMetadata = clientData.metadata?.map((item) => {
      if (item.scope === "susa") {
        return {
          ...item,
          data: {
            ...item.data,
            [name]: value,
          },
        } as SusaMetadata;
      }
      return item;
    });

    setValue("client.metadata", updatedMetadata);
    setIsDirty(true);
  };

  const [fileToDownload, setFileToDownload] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<DocumentType | null>(null);
  const companyName = currentUser.customer.name;

  const typeName: TypeName = {
    avv: "Auftragsverarbeitungsvertrag",
    nda_company: "Vertraulichkeitserklärung",
    contract_company: "Auftragsvereinbarung",
  };

  const handleDownloadDocument = (type: DocumentType) => {
    setSelectedType(type);
    dispatch(
      setCurrentUser({
        loading: true,
      })
    );

    const params = {
      type: type,
      client_id: currentUser.selectedClient.client_id,
      transaction_id: currentUser.transactions[0].transaction_id,
    };

    getDocumentCompany(params)
      .then((response) => {
        setFileToDownload(response.download_url);

        // update transaction state "contract_company_received" after downloading
        const transaction_id = dashboardContext.transactions[0].transaction_id;
        let objTransactionData = {};

        objTransactionData = {
          metadata: [
            {
              scope: "state",
              data: {
                contract_company_received: true,
              },
            },
          ],
        };

        updateTransaction({ transactionId: transaction_id, body: objTransactionData }).then(
          (responseTransaction) => {
            if (responseTransaction) {
              dispatch(
                setCurrentUser({
                  loading: false,
                })
              );
            }
          }
        );
      })
      .catch(() => {
        dispatch(
          setCurrentUser({
            loading: false,
          })
        );
      });
  };

  const handleUpdateClientTransactionData = async () => {
    dispatch(
      setCurrentUser({
        loading: true,
      })
    );

    await updateTransaction({ transactionId: transaction.transaction_id, body: transaction });
    await updateClient({ clientId: clientData.client_id, body: clientData });

    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        company_name: clientData.name,
        contact: {
          first_name: clientData.contact.first_name,
          last_name: clientData.contact.last_name,
          telephone: clientData.contact.telephone,
        },
        address: {
          street: clientData.address.line_1,
          postal: clientData.address.postal,
          city: clientData.address.city,
          country: clientData.address.country,
        },
        profile: {
          ...profileMetadata.data,
        },
        finance: {
          ...susaData.data,
        },
        interest: {
          ...transaction.invest,
        },
      },
    });

    setIsDirty(false);

    dispatch(
      setCurrentUser({
        loading: false,
      })
    );
  };

  useEffect(() => {
    if (transactionByIdQuery.isSuccess) {
      setValue("transaction", transactionByIdQuery.data);
    }
  }, [setValue, transactionByIdQuery]);

  const handelErrorFocus = (control: any): void => {
    setTimeout(() => {
      const fieldError = Object.keys(control._formState.errors)[0];

      if (fieldError) {
        const fieldElements = document.getElementsByName(fieldError);

        if (fieldElements.length > 0) {
          const firstFieldElement = fieldElements[0];
          window.scrollTo({
            top: firstFieldElement.offsetTop,

            behavior: "smooth",
          });
        }
      }
    }, 20);
  };

  useEffect(() => {
    if (!shouldRerender) {
      const timeoutId = setTimeout(() => {
        setShouldRerender(true);
      }, 10);

      return () => clearTimeout(timeoutId);
    }
  }, [shouldRerender]);

  return (
    <Box sx={{ width: "100%", maxWidth: theme.breakpoints.values.xl }} ref={ContentRef}>
      {clientData && (
        <>
          <DetailsCompanyCard
            clientData={clientData}
            handleClientDataChange={handleClientDataChange}
            handleTradeRegistrationChange={handleTradeRegistrationChange}
            loading={false}
            ref={CompanyRef}
            initial={initialLoad}
            setInitial={setInitialLoad}
          />
          <DetailsContactCard
            ref={ContactRef}
            transaction={transaction}
            clientData={clientData}
            handleClientDataChange={handleClientDataChange}
            handlePhoneChange={handlePhoneChange}
            checkPhoneNumber={checkPhoneNumber}
            loading={false}
          />
          <DetailsCompanyInformationCard
            ref={CompanyInformationRef}
            //@ts-ignore
            clientData={clientData}
            productsData={products}
            customersData={customers}
            handleClientDataChange={handleClientDataChange}
            handleCategoriesSelect={handleCategoriesSelect}
            handleProductDataChange={handleProductDataChange}
            handleCustomersDataChange={handleCustomersDataChange}
            loading={false}
            initial={initialLoad}
            setInitial={setInitialLoad}
          />
          <DetailsInterestCard
            ref={DetailsInterestCardRef}
            transaction={transaction}
            handleTransactionDataChange={handleTransactionDataChange}
            handleInvestorProfileAutoCompleteSelect={handleInvestorProfileAutoCompleteSelect}
            loading={transactionByIdQuery.isLoading}
          />
          <DetailsMarketAnalysisCard
            ref={MarketAnalysisRef}
            // @ts-ignore
            transaction={transaction}
            handleTransactionDataChange={handleTransactionDataChange}
            loading={transactionByIdQuery.isLoading}
          />
          <AdditionalCompanyInformation
            ref={AdditionalCompanyInformationRef}
            susa={susaData}
            handleSusaDataChange={handleSusaDataChange}
            handleOwnershipChange={handleOwnershipChange}
          />
          <Section title={t("company/common:onboarding.documents.headline")}>
            <Card>
              <Stack direction="row" spacing={2} ref={DocumentDownloadRef}>
                <DownloadFileCard
                  type={DocumentType.AVV}
                  fileName={t("company/common:onboarding.documents.avv")}
                  handleDownloadDocument={handleDownloadDocument}
                />
                <DownloadFileCard
                  type={DocumentType.NDA_COMPANY}
                  fileName={t("company/common:onboarding.documents.nda")}
                  handleDownloadDocument={handleDownloadDocument}
                />
                <DownloadFileCard
                  type={DocumentType.CONTRACT_COMPANY}
                  fileName={t("company/common:client_agreement.documents.client_agreement")}
                  handleDownloadDocument={handleDownloadDocument}
                />
              </Stack>
              <Dialog
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "70%",
                      maxWidth: "400px",
                      height: "300px",
                    },
                  },
                }}
                open={fileToDownload !== null}
                onClose={() => {
                  setFileToDownload(null);
                }}
              >
                <DialogTitle sx={{ textAlign: "center" }}>
                  {selectedType && (
                    <>
                      <Typography variant="inherit" style={{ fontSize: 24, fontWeight: "bold" }}>
                        {companyName}
                      </Typography>
                      <Typography variant="inherit" style={{ fontSize: 16 }}>
                        {typeName[selectedType]}
                      </Typography>
                    </>
                  )}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "50px",
                      fontSize: 20,
                    }}
                    id="alert-dialog-slide-description"
                  >
                    {t("company/common:secondary_download_dialog.dialog_content_text")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    // size={isMobile ? 'small' : 'medium'}
                    sx={{
                      px: { sx: 2, md: 4 },
                      fontWeight: "bold",
                      color: theme.palette.secondary.main,
                    }}
                    onClick={() => {
                      if (fileToDownload) {
                        window.open(fileToDownload, "_blank");
                        setFileToDownload(null);
                      }
                    }}
                  >
                    Hereunterladen
                  </Button>
                </DialogActions>
              </Dialog>
            </Card>
          </Section>
          <Section
            title={t("details_a3.documents_management_card.headline")}
            ref={DocumentsManagementRef}
          >
            {transaction?.files && (
              <Card>
                <DocumentsTable
                  //@ts-ignore
                  originalFiles={transaction?.files}
                  product={"company"}
                  page={"details"}
                />
              </Card>
            )}
          </Section>
          <Stack direction="row" justifyContent="flex-end">
            <PrimaryButton
              disabled={!isDirty}
              onClick={(): undefined => {
                handleUpdateClientTransactionData();
                handelErrorFocus(control);
                setInitialLoad(false);
                return undefined;
              }}
              title={t("misc.save")}
            />
          </Stack>
        </>
      )}
    </Box>
  );
};
