import React, { createContext, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import Content from "../../../containers/Content";
import SideBar from "../../../containers/SideBar";
import TopBar from "../../../containers/TopBar";
import { DashboardLayout } from "../../../libs/Layout";

import { LoadingIndicator, SidebarSecondary } from "../../../elements/frontend/src/components";

import { ContentWrapper } from "./styled.login";
import { ProcessSteps } from "../../../elements/frontend/src/Store/api";
import { useNavigate, useParams } from "react-router-dom";

// Dashboard Context
const initialDashboardData = {
  company_name: "",
  susaUploaded: false,
  address: {
    street: "",
    postal: "",
    city: "",
    country: "", // "DEU | AUT | CHE"
  },
  contact: {
    first_name: "",
    last_name: "",
    telephone: "",
  },
  profile: {
    legal_form: "",
    fiscal_year: "",
    categories: [],
    trade_registration: "",
    no_bankruptcy: false,
    no_project_business: false,
    website: "",
    nace_code: [],
  },
  interest: {
    capital_request_from: "",
    capital_request_to: "",
  },
  finance: {
    sales_prev_year: "",
    sales_last_year: "",
    ebitda_prev_year: "",
    ebitda_last_year: "",
    number_employees: "",
    ownerships: [],
  },
  calendly: {
    appointments: [],
  },
  transactions: [],
  susa: {
    date: "",
    dateText: "",
    file: null,
  },
  form_state: {
    initial: true,
    valid: false,
  },
  dashboard_steps: [
    { label: "Onboarding", stepId: "ONBOARDING" },
    { label: "Datenimport", stepId: "DATEN_IMPORT" },
    { label: "Persönliches Gespräch", stepId: "PERSONAL_TALK" },
    { label: "Transaktionsprüfung", stepId: "TRANSACTION_CHECK" },
    { label: "Mandatsvertrag", stepId: "CLIENT_AGREEMENT" },
    { label: "Details", stepId: "DETAILS" },
  ],
  dashboard_currentStep: "",
  dashboard_lastStep: "",
  dashboard_completedSteps: [],
  isAdvisorClient: false,
};

export const DashbordContext = createContext(initialDashboardData);
export const DashboardDispatch = createContext((state) => state);

const reducerDashboard = (state, action) => {
  return { ...state, ...action.payload };
};

const Home = () => {
  const params = useParams();
  const step = params.step;
  const navigate = useNavigate();
  const [dashboardContext, dashboardDispatch] = useReducer(reducerDashboard, initialDashboardData);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const [selectedClientId /* setSelectedClientId */] = useState(
    currentUser.selectedClient.client_id
  );

  const selectedClient = useMemo(() => {
    if (!currentUser.clients) return {};
    return currentUser.clients.find((client) => client.client_id === selectedClientId);
  }, [currentUser.clients, selectedClientId]);

  // for the data saved in scope "profile"
  const selectedClientProfileData = useMemo(() => {
    if (!selectedClient.metadata) return {};
    const scopeProfile = selectedClient.metadata.find((item) => item.scope === "profile");
    if (scopeProfile) {
      return scopeProfile.data;
    }
    return {};
  }, [selectedClient.metadata]);

  const { dashboard_currentStep } = selectedClientProfileData;

  // for the data saved in scope "susa"
  const selectedClientSusaData = useMemo(() => {
    if (!selectedClient.metadata) return {};
    const scopeSusa = selectedClient.metadata.find((item) => item.scope === "susa");
    if (scopeSusa) {
      return scopeSusa.data;
    }
    return {};
  }, [selectedClient.metadata]);

  // for the data saved in scope "calendly"
  const selectedClientCalendlyData = useMemo(() => {
    if (!selectedClient.metadata) return {};
    const scopeCalendly = selectedClient.metadata.find((item) => item.scope === "calendly");
    if (scopeCalendly) {
      return scopeCalendly.data;
    }
    return {};
  }, [selectedClient.metadata]);

  // for the data saved in transactions
  const selectedClientTransactionsData = useMemo(() => {
    if (currentUser.transactions.length === 0) return [];
    const transactions = currentUser.transactions.filter(
      (item) => item.client_id === selectedClientId
    );
    if (transactions) {
      return transactions;
    }
    return [];
  }, [currentUser.transactions, selectedClientId]);

  useEffect(() => {
    if (selectedClient === undefined) return;
    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        company_name: selectedClient.name,
        contact: {
          first_name: selectedClient?.contact?.first_name || "",
          last_name: selectedClient?.contact?.last_name || "",
          telephone: selectedClient?.contact?.telephone || "",
        },
        address: {
          street: selectedClient.address.line_1 || "",
          postal: selectedClient.address.postal || "",
          city: selectedClient.address.city || "",
          country: selectedClient.address.country || "",
        },
        profile: selectedClientProfileData
          ? {
              legal_form: selectedClientProfileData.legal_form || "",
              fiscal_year: selectedClientProfileData.fiscal_year || "",
              categories: selectedClientProfileData.categories || [],
              sub_categories: selectedClientProfileData.sub_categories || [],
              trade_registration: selectedClientProfileData.trade_registration || "",
              no_bankruptcy: selectedClientProfileData.no_bankruptcy || false,
              no_project_business: selectedClientProfileData.no_project_business || false,
              website: selectedClientProfileData.website || "",
              nace_code: selectedClientProfileData.nace_code || [],
            }
          : {},
        finance: selectedClientSusaData
          ? {
              sales_prev_year: selectedClientSusaData.sales_prev_year || "",
              sales_last_year: selectedClientSusaData.sales_last_year || "",
              ebitda_prev_year: selectedClientSusaData.ebitda_prev_year || "",
              ebitda_last_year: selectedClientSusaData.ebitda_last_year || "",
              ebit_prev_year: selectedClientSusaData.ebit_prev_year || "",
              ebit_last_year: selectedClientSusaData.ebit_last_year || "",
              number_employees_prev_year: selectedClientSusaData.number_employees_prev_year || "",
              number_employees_last_year: selectedClientSusaData.number_employees_last_year || "",
              ownerships: selectedClientSusaData.ownerships || [],
            }
          : {},
        interest:
          selectedClientTransactionsData.length > 0
            ? {
                min_cap: selectedClientTransactionsData[0].invest?.min_cap || "",
                max_cap: selectedClientTransactionsData[0].invest?.max_cap || "",
                self_ev: selectedClientTransactionsData[0].invest?.self_ev || "",
                phase: selectedClientTransactionsData[0].invest?.phase || "",
                type: selectedClientTransactionsData[0].invest?.type || "",
                stake: selectedClientTransactionsData[0].invest?.stake || "",
                period: selectedClientTransactionsData[0].invest?.period || "",
                structure: selectedClientTransactionsData[0].invest?.structure || "",
              }
            : {},
        calendly: selectedClientCalendlyData
          ? {
              appointments: selectedClientCalendlyData.appointments || [],
            }
          : {},
        transactions: selectedClientTransactionsData,
        dashboard_currentStep: selectedClientProfileData.dashboard_currentStep || "ONBOARDING",
        dashboard_lastStep: selectedClientProfileData.dashboard_lastStep || "ONBOARDING",
        dashboard_completedSteps: selectedClientProfileData.dashboard_completedSteps || [],
        isAdvisorClient: !!currentUser.user?.client_id,
      },
    });
  }, [
    currentUser.user?.client_id,
    selectedClient,
    selectedClientCalendlyData,
    selectedClientProfileData,
    selectedClientSusaData,
    selectedClientTransactionsData,
  ]);

  useEffect(() => {
    const { dashboard_currentStep } = selectedClientProfileData;
    if (
      dashboard_currentStep !== ProcessSteps.DETAILS &&
      dashboard_currentStep !== ProcessSteps.DUE_DILIGENCE &&
      dashboard_currentStep !== ProcessSteps.INVESTMENT_DOCUMENTS &&
      selectedClientTransactionsData.length > 0
    ) {
      navigate(`/`);
      return;
    }

    if (step) {
      return;
    }

    if (dashboard_currentStep === "DETAILS" && selectedClientTransactionsData.length > 0) {
      navigate(`/${selectedClientTransactionsData[0].transaction_id}/details`);
    }

    if (dashboard_currentStep === "DUE_DILIGENCE" && selectedClientTransactionsData.length > 0) {
      navigate(`/${selectedClientTransactionsData[0].transaction_id}/due-diligence`);
    }

    if (
      dashboard_currentStep === "INVESTMENT_DOCUMENTS" &&
      selectedClientTransactionsData.length > 0
    ) {
      navigate(`/${selectedClientTransactionsData[0].transaction_id}/investment-documents`);
    }
  }, [navigate, selectedClientProfileData, selectedClientTransactionsData, step]);

  return (
    <>
      <DashboardLayout>
        <TopBar />
        <DashboardDispatch.Provider value={dashboardDispatch}>
          <DashbordContext.Provider value={dashboardContext}>
            <ContentWrapper>
              {dashboard_currentStep !== ProcessSteps.DETAILS && <SideBar />}
              {(dashboard_currentStep === ProcessSteps.DETAILS ||
                dashboard_currentStep === ProcessSteps.DUE_DILIGENCE ||
                dashboard_currentStep === ProcessSteps.INVESTMENT_DOCUMENTS) && (
                <SidebarSecondary />
              )}
              <Content />
            </ContentWrapper>
          </DashbordContext.Provider>
        </DashboardDispatch.Provider>
      </DashboardLayout>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
    </>
  );
};

export default Home;
