import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import "./i18n";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

// See https://serverless-stack.com/chapters/configure-aws-amplify.html
import { Amplify } from "aws-amplify";
import TAwsConfig from "./awsConfig";

import config from "./config";

// Redux and store
import { Provider } from "react-redux";

import { ErrorBoundary } from "./elements/frontend/src/components";
import { LoadingIndicator } from "./elements/frontend/src/components";
import { store } from "./elements/frontend/src/Store";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: TAwsConfig.cognito.REGION,
    userPoolId: TAwsConfig.cognito.USER_POOL_ID,
    identityPoolId: TAwsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: TAwsConfig.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: TAwsConfig.s3.REGION,
    bucket: TAwsConfig.s3.BUCKET,
    identityPoolId: TAwsConfig.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: config.webapp.APP_API_ID,
        endpoint: TAwsConfig.apiGateway.URL,
        region: TAwsConfig.apiGateway.REGION,
      },
      {
        name: config.webapp.SIGNUP_API_ID,
        endpoint: TAwsConfig.registration.URL,
        region: TAwsConfig.apiGateway.REGION,
      },
    ],
  },
});

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <ErrorBoundary>
            <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
              <App />
            </Suspense>
          </ErrorBoundary>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
