import { rgba } from "polished";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

export const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: rgba(theme.palette.primary.main, 0.8),
      color: theme.palette.common.white,
      fontWeight: "bold",
      fontSize: 14,
      [theme.breakpoints.up("md")]: {
        fontSize: 15,
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      [theme.breakpoints.up("md")]: {
        fontSize: 15,
      },
    },
    color: "inherit",
  };
});

export const StyledTableRow = styled(TableRow)(({ theme, ...props }) => {
  const backgroundColor = props.className === "expired" ? "gray" : "";
  const oddColor = props.className !== "expired" ? theme.palette.action.hover : "";
  const textColor = props.className === "expired" ? "#bbb" : "";

  return {
    textDecoration: "line-trough",
    "&:nth-of-type(odd)": {
      backgroundColor: oddColor,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    backgroundColor: backgroundColor,
    color: textColor,
  };
});
