/* eslint-disable no-mixed-operators */
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

import { Card, LoadingIndicator } from "../../../elements/frontend/src/components";
import { thousandSeparator } from "../../../elements/frontend/src/common";
import { Section } from "../../../elements/frontend/src/components/Section/Section";
import { CapitalSecondaryDetails } from "../../../elements/frontend/src/components/CapitalSecondary/CapitalSecondaryDetails";
import { InvestStructure, Transaction } from "../../../elements/frontend/src/Store/api";
import { useAppSelector } from "../../../elements/frontend/src/Store/hooks/useAppSelector";
import { CurrencyFormatter } from "../../../elements/frontend/src/hooks/formatter/CurrencyFormatter/CurrencyFormatter";
import { FieldErrors } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import { OnboardingFormValues } from "../interfaces";

interface DetailsInterestCardProps {
  transaction: Transaction;
  setDetailsTransaction?: () => void;
  handleTransactionDataChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => void;
  handleInvestorProfileAutoCompleteSelect: (
    event: React.ChangeEvent | React.SyntheticEvent<Element, Event>,
    value: { value: string; summary: string }[],
    field: string
  ) => void;
  loading: boolean;
  formErrors?: FieldErrors;
}

export const DetailsInterestCard = React.forwardRef(
  (
    {
      transaction,
      setDetailsTransaction,
      handleTransactionDataChange,
      handleInvestorProfileAutoCompleteSelect,
      loading,
      formErrors,
    }: DetailsInterestCardProps,
    ref: React.ForwardedRef<unknown>
  ) => {
    const { t } = useTranslation();
    const { control } = useFormContext<OnboardingFormValues>();

    const currentUser = useAppSelector(({ currentUser }) => currentUser);

    const minCap = parseFloat(transaction?.invest?.min_cap || "");
    const maxCap = parseFloat(transaction?.invest?.max_cap || "");
    const errorMessage = maxCap <= minCap ? t("details_a3.interest_card.max_cap_error") : undefined;

    // to convert Autocomplete array values to objects with value, summary fields
    const convertToObjValue = useCallback(
      (value: any, field: string) => {
        if (field === "investorPhase" || field === "period") {
          // phase and period have single selection
          return currentUser?.datastore[field]?.find((obj: any) => obj.value === value);
        } else if (value !== "" && (field === "companyStake" || field === "types")) {
          // stake and type have multi selection
          return value?.map((item: string) =>
            currentUser?.datastore[field]?.find((obj: any) => obj.value === item)
          );
        } else if (field === "transactionStructure") {
          return currentUser?.datastore[field]?.find((obj: any) => obj.value === value);
        }
      },
      [currentUser.datastore]
    );

    // to get capital name according to code
    const getName = useCallback(
      (val: string) => {
        if (currentUser?.datastore?.transactionStructure.length === 0) return;
        const capital = currentUser?.datastore?.transactionStructure.find(
          (item: any) => item.value === val
        );
        return capital?.summary;
      },
      [currentUser?.datastore?.transactionStructure]
    );

    // in order to create investmentStructure options for the first AutoComplete Component
    const structureOptions = useMemo(() => {
      const items = [];
      for (const level1 of currentUser?.datastore?.transactionStructure) {
        items.push({
          cat: level1.value,
          summary: getName(level1.value),
        });
      }
      return items;
    }, [currentUser?.datastore?.transactionStructure, getName]);

    return (
      <>
        <Section title={t("details_a3.interest_card.headline")} ref={ref}>
          <Card>
            {loading && <LoadingIndicator type={"COMPONENT"} />}
            {!loading && (
              <>
                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                  <Controller
                    name="min_cap"
                    control={control}
                    render={({ fieldState: { error }, field: { onChange, value } }) => (
                      <TextField
                        name="min_cap"
                        required
                        label={t("details_a3.interest_card.capital_request_from")}
                        id="details_a3.interest_card_min_cap"
                        value={transaction?.invest?.min_cap || ""}
                        size="small"
                        fullWidth
                        onChange={(e) => handleTransactionDataChange(e, "invest")}
                        error={
                          (transaction?.invest?.min_cap === ""
                            ? !!formErrors?.min_cap?.message
                            : errorMessage) as boolean
                        }
                        helperText={
                          transaction?.invest?.min_cap === ""
                            ? (formErrors?.min_cap?.message as string)
                            : ""
                        }
                        InputProps={{
                          inputComponent: CurrencyFormatter as any,
                        }}
                        inputProps={{
                          name: "min_cap",
                          currency: "EUR",
                          autoComplete: "new-min_cap",
                          form: {
                            autoComplete: "off",
                          },
                          inputMode: "numeric",
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="max_cap"
                    control={control}
                    render={({ fieldState: { error }, field: { onChange, value } }) => (
                      <TextField
                        label={t("details_a3.interest_card.capital_request_to")}
                        name="max_cap"
                        id="details_a3.interest_card_max_cap"
                        value={transaction?.invest?.max_cap || ""}
                        required
                        size="small"
                        fullWidth
                        onChange={(e) => handleTransactionDataChange(e, "invest")}
                        error={
                          (transaction?.invest?.max_cap === ""
                            ? !!formErrors?.max_cap?.message
                            : errorMessage) as boolean
                        }
                        helperText={
                          transaction?.invest?.max_cap === ""
                            ? (formErrors?.max_cap?.message as string)
                            : errorMessage
                        }
                        InputProps={{
                          inputComponent: CurrencyFormatter as any,
                        }}
                        inputProps={{
                          name: "max_cap",
                          currency: "EUR",
                          autoComplete: "new-max_cap",
                          form: {
                            autoComplete: "off",
                          },
                          inputMode: "numeric",
                        }}
                      />
                    )}
                  />
                </Stack>

                {/* spread the row to be into 2 column */}
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ my: 2 }}
                >
                  <TextField
                    label={t("details_a3.interest_card.company_value")}
                    name="self_ev"
                    id="details_a3.interest_card_self_ev"
                    value={thousandSeparator(transaction?.invest?.self_ev) || ""}
                    size="small"
                    fullWidth
                    onChange={(e) => handleTransactionDataChange(e, "invest")}
                    InputProps={{
                      inputComponent: CurrencyFormatter as any,
                    }}
                    inputProps={{
                      currency: "EUR",
                      inputMode: "numeric",
                    }}
                  />
                  {/** * PHASE ***/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      id="details_a3.interest_card_phase"
                      freeSolo
                      options={currentUser?.datastore?.investorPhase}
                      getOptionLabel={(option) => option?.summary}
                      value={
                        transaction?.invest?.phase
                          ? convertToObjValue(transaction?.invest?.phase, "investorPhase")
                          : {
                              value: "",
                              summary: "",
                            }
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label={t("details_a3.interest_card.phase")} />
                      )}
                      onChange={(e, value) =>
                        handleInvestorProfileAutoCompleteSelect(e, value, "phase")
                      }
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ my: 2 }}
                >
                  {/** * TYPES ***/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      multiple
                      id="details_a3.interest_card_type"
                      freeSolo
                      options={currentUser?.datastore?.types}
                      getOptionLabel={(option) => option?.summary}
                      value={
                        (transaction?.invest?.type !== undefined &&
                          convertToObjValue(transaction?.invest?.type, "types")) ||
                        []
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label={t("details_a3.interest_card.types")} />
                      )}
                      onChange={(e, value) =>
                        handleInvestorProfileAutoCompleteSelect(e, value, "type")
                      }
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.multi_select")}
                    </Typography>
                  </FormControl>

                  {/** * STAKE ***/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      multiple
                      id="details_a3.interest_card_stake"
                      freeSolo
                      options={currentUser?.datastore?.companyStake}
                      getOptionLabel={(option) => option?.summary}
                      value={
                        (transaction?.invest?.stake !== undefined &&
                          convertToObjValue(transaction?.invest?.stake, "companyStake")) ||
                        []
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label={t("details_a3.interest_card.stake")} />
                      )}
                      onChange={(e, value) =>
                        handleInvestorProfileAutoCompleteSelect(e, value, "stake")
                      }
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.multi_select")}
                    </Typography>
                  </FormControl>
                </Stack>

                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ mt: 2 }}
                >
                  {/** * PERIOD * Investmenthorizont**/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      id="details_a3.interest_card_period"
                      freeSolo
                      options={currentUser?.datastore?.period}
                      getOptionLabel={(option) => option?.summary}
                      value={
                        convertToObjValue(
                          transaction?.invest?.period !== undefined && transaction?.invest?.period,
                          "period"
                        ) || {
                          value: "",
                          summary: "",
                        }
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} label={t("details_a3.interest_card.period")} />
                      )}
                      onChange={(e, value) =>
                        handleInvestorProfileAutoCompleteSelect(e, value, "period")
                      }
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                  </FormControl>

                  {/* Investmentstruktur */}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      id="details_a3.interest_card_invest_structure"
                      freeSolo
                      options={currentUser?.datastore?.transactionStructure && structureOptions}
                      getOptionLabel={(option) => option?.summary}
                      value={
                        convertToObjValue(
                          transaction?.invest?.structure !== undefined &&
                            transaction?.invest?.structure,
                          "transactionStructure"
                        ) || {
                          value: "",
                          summary: "",
                        }
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("details_a3.interest_card.invest_structure")}
                        />
                      )}
                      onChange={(e, value) => {
                        handleInvestorProfileAutoCompleteSelect(e, value, "structure");
                      }}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.summary === value.summary
                      }
                    />
                  </FormControl>
                </Stack>
              </>
            )}
            {transaction?.invest?.structure === InvestStructure.CapitalSecondary ||
            transaction?.invest?.structure === InvestStructure.Secondary ? (
              <Stack sx={{ mt: 6 }} spacing={{ xs: 2, md: 4 }}>
                <CapitalSecondaryDetails
                  transactionId={transaction.transaction_id}
                  setDetailsTransaction={setDetailsTransaction}
                />
              </Stack>
            ) : null}
          </Card>
        </Section>
      </>
    );
  }
);
