import React, { useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useTransactions } from "../../../../hooks";
import { useParams } from "react-router-dom";
import { useCustomers } from "../../../../hooks";
import { TextFieldComponent } from "./helper";
import { useAppSelector } from "../../../../Store/hooks/useAppSelector";

export const FinancialRow = ({
  handelChange,
  financialinformation,
  yearIndex,
  metric,
  year,
  data,
}) => {
  return (
    <td key={yearIndex} style={{ width: "9%" }}>
      <TextFieldComponent
        val={{
          name: "financialinformation",
          value: financialinformation?.[metric?.name]
            ? financialinformation[metric.name][year] || data
            : data,
        }}
        handleChange={(e) => handelChange(metric.name, year, e.target.value)}
      />
    </td>
  );
};
export const FinancialMetricsTable = ({
  financialMetrics,
  handelChange,
  financialinformation,
  lastSixYears,
}) => {
  const { transactionId } = useParams();
  const { getTransactionById } = useTransactions();
  const { getCustomerById } = useCustomers();
  const [customer, setCustomer] = React.useState(null);
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const productId = currentUser?.customer?.subscription?.product_id;

  useEffect(() => {
    const getTransaction = async () => {
      const transaction = await getTransactionById(transactionId);
      const customerId = transaction?.transaction.customer_id;
      const customer = await getCustomerById(customerId);
      setCustomer(customer);
    };

    getTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentYear = new Date().getFullYear();
  const lastYear = currentYear - 1;
  const prevYear = currentYear - 2;
  const customerData = productId === "platform" ? customer : currentUser;

  const ebitda_last_year = customerData?.customer?.metadata?.find((item) => item.scope === "susa")
    ?.data.ebitda_last_year;
  const ebitda_prev_year = customerData?.customer?.metadata?.find((item) => item.scope === "susa")
    ?.data.ebitda_prev_year;
  const sales_last_year = customerData?.customer?.metadata?.find((item) => item.scope === "susa")
    ?.data.sales_last_year;
  const sales_prev_year = customerData?.customer?.metadata?.find((item) => item.scope === "susa")
    ?.data.sales_prev_year;

  const metricValues = {
    EBITDA: {
      [lastYear]: ebitda_last_year,
      [prevYear]: ebitda_prev_year,
    },
    Umsatzerlöse: {
      [lastYear]: sales_last_year,
      [prevYear]: sales_prev_year,
    },
  };

  return (
    <>
      {financialMetrics?.map((metric, metricIndex) => (
        <tr key={metricIndex}>
          <td>
            <Tooltip title={metric.label}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
            {metric.name}
          </td>
          {lastSixYears.map((year, yearIndex) => {
            const data = metricValues[metric.name]?.[year] || "";

            return (
              <FinancialRow
                handelChange={handelChange}
                financialinformation={financialinformation}
                yearIndex={yearIndex}
                metric={metric}
                year={year}
                data={data}
              />
            );
          })}
        </tr>
      ))}
    </>
  );
};
