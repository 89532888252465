import React, { useState } from "react";
import { Card, DownloadFileCard, Section } from "../../../elements/frontend/src/components";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { setCurrentUser } from "../../../elements/frontend/src/Store/currentUser/currentUserSlice";
import {
  TypeName,
  DocumentType,
} from "../../../elements/frontend/src/Store/api/documents/interfaces";
import { useAppDispatch } from "../../../elements/frontend/src/Store/hooks/useAppDispatch";
import { useDocuments } from "../../../elements/frontend/src/hooks";
import { useFormContext } from "react-hook-form";
import { OnboardingFormValues, OnboardingSteps } from "../interfaces";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../../elements/frontend/src/Store/hooks/useAppSelector";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const Documents = () => {
  const theme = useTheme();
  const { t } = useTranslation(["company/common"]);
  const dispatch = useAppDispatch();
  const { getDocumentCompany } = useDocuments();
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<OnboardingFormValues>();
  const currentStep = watch("currentStep");
  const avvReceived = watch("avv_received");
  const ndaReceived = watch("nda_received");
  const currentUser: any = useAppSelector(({ currentUser }) => currentUser);
  const companyName = currentUser.customer.name;

  const [fileToDownload, setFileToDownload] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<DocumentType | null>(null);

  const typeName: TypeName = {
    avv: "Auftragsverarbeitungsvertrag",
    nda_company: "Vertraulichkeitserklärung",
    contract_company: "Auftragsvereinbarung",
  };

  const handleDownloadDocument = async (type: DocumentType) => {
    setSelectedType(type);
    dispatch(
      setCurrentUser({
        loading: true,
      })
    );

    const params = {
      type: type,
      client_id: currentUser.selectedClient.client_id,
      transaction_id: currentUser.transactions[0].transaction_id,
    };

    getDocumentCompany(params).then((response) => {
      setFileToDownload(response.download_url);


      if (type === DocumentType.AVV) {
        setValue("avv_received", true, { shouldValidate: true });
      }

      if (type === DocumentType.NDA_COMPANY) {
        setValue("nda_received", true, { shouldValidate: true });
      }

      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    });
  };

  return (
    <>
      {/* @ts-ignore */}
      <Section title={t("company/common:onboarding.documents.headline")}>
        <Card>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <DownloadFileCard
                type={DocumentType.AVV}
                fileName={t("company/common:onboarding.documents.avv")}
                handleDownloadDocument={handleDownloadDocument}
                hasError={!!errors.avv_received?.message}
                disabled={!avvReceived && currentStep === OnboardingSteps.COLLECT_DATA}
              />
              <DownloadFileCard
                type={DocumentType.NDA_COMPANY}
                fileName={t("company/common:onboarding.documents.nda")}
                handleDownloadDocument={handleDownloadDocument}
                hasError={!!errors.nda_received?.message}
                disabled={!ndaReceived && currentStep === OnboardingSteps.COLLECT_DATA}
              />
            </Stack>
            <Dialog
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "70%",
                    maxWidth: "400px",
                    height: "300px",
                  },
                },
              }}
              open={fileToDownload !== null}
              onClose={() => {
                setFileToDownload(null);
              }}
            >
              <DialogTitle sx={{ textAlign: "center" }}>
                {selectedType && (
                  <>
                    <Typography variant="inherit" style={{ fontSize: 24, fontWeight: "bold" }}>
                      {companyName}
                    </Typography>
                    <Typography variant="inherit" style={{ fontSize: 16 }}>
                      {typeName[selectedType]}
                    </Typography>
                  </>
                )}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "50px",
                    fontSize: 20,
                  }}
                  id="alert-dialog-slide-description"
                >
                  {t("company/common:secondary_download_dialog.dialog_content_text")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  // size={isMobile ? 'small' : 'medium'}
                  sx={{
                    px: { sx: 2, md: 4 },
                    fontWeight: "bold",
                    color: theme.palette.secondary.main,
                  }}
                  onClick={() => {
                    if (fileToDownload) {
                      window.open(fileToDownload, "_blank");
                      setFileToDownload(null);
                    }
                  }}
                >
                  Hereunterladen
                </Button>
              </DialogActions>
            </Dialog>
            <Typography
              variant="body1"
              sx={{
                color:
                  !!errors.avv_received?.message || !!errors.nda_received?.message
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
              }}
            >
              {t("company/common:onboarding.documents.hint")}
            </Typography>
          </Stack>
        </Card>
      </Section>
    </>
  );
};
