import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { darken } from "@mui/system";

import { Wrapper } from "./styled.sidebar";
import { DashboardDispatch, DashbordContext } from "../../views/Private/Home";
import { getPredictedNextStep } from "../../utils/step.utils";
import { ProcessSteps } from "../../elements/frontend/src/Store/api";

// custom Tooltip
const CustomTooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: darken(theme.palette.primary.main, 0.4),
    color: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    fontSize: 12,
    fontWeight: "bold",
    padding: "7px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: darken(theme.palette.primary.main, 0.4),
  },
}));

const SideBar = () => {
  const { t } = useTranslation(["company/common"]);

  const dashboardContext = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);

  const theme = useTheme();
  const isTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  const transaction = dashboardContext.transactions[0];
  const hasOnboardCall = transaction?.state?.steps?.onboard_call?.completed;
  // to define dashboard steps according to selected client.
  // advisor client or self registered company
  // for self registered company show "PERSONAL_TALK", for advisor client show "TRANSACTION_CHECK"
  const dashBoardSteps = useMemo(() => {
    if (dashboardContext.isAdvisorClient) {
      return dashboardContext.dashboard_steps.filter((item) => item.stepId !== "PERSONAL_TALK");
    }
    return dashboardContext.dashboard_steps;
  }, [dashboardContext.dashboard_steps, dashboardContext.isAdvisorClient]);

  // to define the lastStep index
  const lastStepIndex = useMemo(() => {
    return dashBoardSteps.findIndex(
      ({ stepId }) => stepId === dashboardContext.dashboard_currentStep
    );
  }, [dashBoardSteps, dashboardContext.dashboard_currentStep]);

  // to change the UI according to click event. User can navigate between menu components
  const changeCurrentStep = (stepId) => {
    const predictedNextStep = getPredictedNextStep(dashboardContext.dashboard_completedSteps);
    if (stepId === ProcessSteps.PERSONAL_TALK && hasOnboardCall) {
      return;
    }

    if (
      stepId &&
      (dashboardContext.dashboard_completedSteps.includes(stepId) || predictedNextStep === stepId)
    ) {
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          dashboard_currentStep: stepId,
        },
      });
    }
  };

  const getStepLabelClassNames = (step, currentStep) => {
    const predictedNextStep = getPredictedNextStep(dashboardContext.dashboard_completedSteps);

    if (step === ProcessSteps.PERSONAL_TALK && hasOnboardCall) {
      return "disabled";
    }

    if (step === currentStep) {
      return "active";
    }

    if (dashboardContext.dashboard_completedSteps.includes(step) || predictedNextStep === step) {
      return "completed";
    } else {
      return "disabled";
    }
  };

  const isDisabled = (step, currentStep) => {
    const predictedNextStep = getPredictedNextStep(dashboardContext.dashboard_completedSteps);

    if (
      predictedNextStep !== currentStep &&
      (dashboardContext.dashboard_completedSteps.includes(step) || step === currentStep)
    ) {
      return false;
    } else {
      return false;
    }
  };

  return (
    <Wrapper>
      <Box sx={{ maxWidth: 400 }}>
        <Typography
          variant="h5"
          align="left"
          sx={{
            fontWeight: "bold",
            mb: 1,
            paddingLeft: "40px",
            fontSize: { xs: "1rem", md: "1.3rem" },
            color: theme.palette.common.white,
          }}
        >
          {t("company/common:sidebar.headline")}
        </Typography>
        <Stepper
          nonLinear={true}
          activeStep={lastStepIndex}
          orientation="vertical"
          sx={{ cursor: "pointer" }}
        >
          {isTablet &&
            dashBoardSteps &&
            dashBoardSteps.map((step, index) => (
              <CustomTooltipWrapper key={step.label} title={step.label} placement="right" arrow>
                <Step completed={true} key={step.label}>
                  <StepLabel
                    onClick={() => index <= lastStepIndex && changeCurrentStep(step.stepId)}
                    className={index <= lastStepIndex ? "completed" : "notCompleted"}
                  >
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      className="textLabel"
                      sx={{
                        fontWeight: index === lastStepIndex ? "bold" : "normal",
                        "&:hover": { cursor: index <= lastStepIndex ? "pointer" : "initial" },
                      }}
                    >
                      {step.label}
                    </Typography>
                  </StepLabel>
                </Step>
              </CustomTooltipWrapper>
            ))}
          {!isTablet &&
            dashBoardSteps &&
            dashBoardSteps.map((step, index) => (
              <Step
                key={step.label}
                completed={dashboardContext.dashboard_completedSteps.includes(step.stepId)}
                onClick={() => changeCurrentStep(step.stepId)}
                disabled={isDisabled(step.stepId, dashboardContext.dashboard_currentStep)}
              >
                <StepLabel
                  onClick={() => index <= lastStepIndex && changeCurrentStep(step.stepId)}
                  className={getStepLabelClassNames(
                    step.stepId,
                    dashboardContext.dashboard_currentStep
                  )}
                >
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    className="textLabel"
                    sx={{
                      fontWeight: index === lastStepIndex ? "bold" : "normal",
                      "&:hover": { cursor: index <= lastStepIndex ? "pointer" : "initial" },
                    }}
                  >
                    {step.label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
        </Stepper>
      </Box>
    </Wrapper>
  );
};

export default SideBar;
