import {
  Card,
  Phone,
  Section,
  UserManagementInContact,
} from "../../../elements/frontend/src/components";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import TConfig from "../../../config";
//import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { OnboardingFormValues } from "../interfaces";
import { useAppSelector } from "../../../elements/frontend/src/Store/hooks/useAppSelector";

export const Contact = () => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const selectedClientId = currentUser.selectedClient.client_id;
  const customerId = currentUser?.customer?.customer_id;

  const { t } = useTranslation(["company/common"]);
  const { control /* , watch */ } = useFormContext<OnboardingFormValues>();

  return (
    <>
      {/* @ts-ignore */}
      <Section title={t("company/common:onboarding.contact.headline")}>
        <Card>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            <Controller
              name="first_name"
              control={control}
              render={({ fieldState: { error }, field: { onChange, value } }) => (
                <TextField
                  name="first_name"
                  label={t("company/common:onboarding.contact.first_name")}
                  value={value}
                  size="small"
                  required
                  fullWidth
                  onChange={onChange}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-firstname",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />

            <Controller
              name="last_name"
              control={control}
              render={({ fieldState: { error }, field: { onChange, value } }) => (
                <TextField
                  name="last_name"
                  label={t("company/common:onboarding.contact.last_name")}
                  value={value}
                  size="small"
                  required
                  fullWidth
                  onChange={onChange}
                  error={!!error}
                  helperText={!!error && t("misc.required_field")}
                  inputProps={{
                    autoComplete: "new-lastname",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 2, mb: 3 }}>
            <Controller
              name="telephone"
              control={control}
              render={({ fieldState: { error }, field: { onChange, value } }) => (
                <Phone
                  telephone={value}
                  label={t("company/common:onboarding.contact.phone")}
                  phoneChange={onChange}
                  mobile={true}
                  error={!!error?.message}
                  errorText={error?.message ? error?.message : ""}
                  registering={false}
                  idLabel={"contact"}
                />
              )}
            />
          </Stack>
          <UserManagementInContact
            product={TConfig.defaults.PRODUCT_ID}
            customerId={customerId}
            clientId={selectedClientId}
            transactionType={"company"}
          />
        </Card>
      </Section>
    </>
  );
};
