import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { Section, Card } from "../../elements/frontend/src/components";

const TransactionCheck = () => {
  const { t } = useTranslation(["company/common"]);
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", maxWidth: theme.breakpoints.values.xl }}>
      {/****** TRANSACTION CHECK ************/}
      <Section title={t("company/common:transaction_check.headline")}>
        <Card>
          <Stack direction="row" spacing={2}>
            {t("company/common:transaction_check.text")}
          </Stack>
        </Card>
      </Section>
    </Box>
  );
};

export default TransactionCheck;
