import { Step, Wrapper } from "./styled.progress";

export const Progress = ({ steps, currentStep }) => {
  return (
    <Wrapper>
      {steps.map(({ stepId, label }, index) => {
        return (
          <Step key={index} stepId={stepId} currentStep={currentStep} steps={steps}>
            {label}
          </Step>
        );
      })}
    </Wrapper>
  );
};
