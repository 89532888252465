import React from "react";
import { Wrapper } from "./styled.topbar";
import UserMenu from "./UserMenu";

import { Logo } from "../../elements/frontend/src/components";

const TopBar = () => {
  return (
    <Wrapper>
      <Logo />
      <UserMenu />
    </Wrapper>
  );
};

export default TopBar;
