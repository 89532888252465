import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, Stack } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
import { useTransactions } from "../../elements/frontend/src/hooks";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../elements/frontend/src/Store/hooks/useAppDispatch";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

export default function PersonalTalkPopup({ handleNext }: any) {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const transactionId = currentUser.transactions[0].transaction_id;
  const { getTransactionById, updateTransaction } = useTransactions();
  const dispatch = useAppDispatch();
  const transaction = getTransactionById(transactionId);
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const { t } = useTranslation(["company/common"]);
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  const handleStep = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      const objectToUpdate = {
        ...transaction,
        metadata: [
          {
            scope: "state",
            data: {
              onboard_call: "true",
              onboard_call_sheduled: "true",
            },
          },
        ],
      };

      await updateTransaction(transactionId, objectToUpdate);

      handleNext();
      dispatch(setCurrentUser({ loading: false }));
    } catch (e) {}
  };

  return (
    <div>
      {open && (
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <InfoOutlinedIcon sx={{ mr: 1, mt: 2 }} />
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                {t("company/common:personal_talk.notification")}
              </Typography>
            </Box>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size={isMobile ? "small" : "medium"}
                style={{ marginBottom: "0" }}
                sx={{
                  px: { xs: 3, md: 2 },
                  py: 1,
                  mr: 1,
                  mb: 5,
                  mt: 2,
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                  borderRadius: "4px",
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                {t("company/common:personal_talk.no")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size={isMobile ? "small" : "medium"}
                style={{ marginBottom: "0" }}
                sx={{
                  px: { xs: 3, md: 2 },
                  py: 1,
                  mr: 1,
                  mb: 5,
                  mt: 2,
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                  borderRadius: "4px",
                }}
                onClick={() => {
                  handleClose();
                  handleStep();
                }}
              >
                {t("company/common:personal_talk.yes")}
              </Button>
            </Stack>
          </Box>
        </Modal>
      )}
    </div>
  );
}
