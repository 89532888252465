import { styled } from "@mui/material/styles";
import { rgba } from "polished";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    gridArea: "sidebar",
    width: "55px",
    padding: "10px 10px 10px 17px",
    overflow: "hidden",
    position: "fixed",
    top: "90px",
    left: 0,
    bottom: 0,

    [theme.breakpoints.up("md")]: {
      width: "280px",
      padding: "35px 15px 10px 25px",
      top: "110px",
    },

    "& svg": {
      fontSize: "25px",
      [theme.breakpoints.up("md")]: {
        fontSize: "30px",
      },
      fontWeight: "bold",
      "& circle": {
        fill: theme.palette.grey[100],
        stroke: theme.palette.grey[100],
        strokeWidth: "1px",
        r: 11,
      },
      "& text": {
        fill: theme.palette.primary.main,
      },
    },

    "& .notCompleted svg": {
      "& circle": {
        fill: rgba(theme.palette.grey[300], 0.5),
        stroke: rgba(theme.palette.grey[300], 0.5),
      },
    },

    "& svg path": {
      fill: theme.palette.grey[100],
    },

    "& .textLabel": {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "inline-block",
        width: "100%",
        padding: "5px 0 5px 12px",
        marginLeft: "5px",
      },
    },

    "& .active .textLabel": {
      color: theme.palette.primary.main,
      borderRadius: "5px",
      backgroundColor: rgba(theme.palette.grey[50], 1),
      transition: "transform 0.4s",
      "&:hover": {
        transform: "translateY(-3px)",
      },
    },

    "& .completed .textLabel": {
      color: theme.palette.primary.main,
      borderRadius: "5px",
      backgroundColor: rgba(theme.palette.grey[50], 1),
      transition: "transform 0.4s",
      "&:hover": {
        transform: "translateY(-3px)",
      },
    },

    "& .disabled .textLabel": {
      color: theme.palette.grey[400],
      borderRadius: "5px",
      backgroundColor: rgba(theme.palette.grey[400], 0.5),

      // "&:hover": {
      //   pointerEvents: "none",
      //   cursor: "default",
      // },
    },
  };
});
