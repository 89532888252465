import { Card, Section } from "../..";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const Tax = () => {
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", maxWidth: theme.breakpoints.values.xl }}>
      {/* @ts-ignore */}
      <Section>
        {/* @ts-ignore */}
        <Card></Card>
      </Section>
    </Box>
  );
};
