import { expirableLocalStorage } from "expirable-storage";
import { useState, useEffect } from "react";
import axios from "axios";

const INTEGRATION_KEY = "8e3fca8d-2ec0-4bca-b638-fb516bf980d7";

const AUTH_URL = "https://account.docusign.com/oauth/auth";
const INFO_URL = "https://account.docusign.com/oauth/userinfo";
const DOCU_SIGN_URL = "https://eu.docusign.net/restapi/v2.1";

export async function createAndSendEnvelope(accessToken, accountId, emails, documentUrl) {
  const documentData = (
    await axios.get(documentUrl, {
      responseType: "arraybuffer",
    })
  ).data;
  var base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(documentData)));

  const req = {
    emailSubject: "Please sign the attached Mandatsvertrag",
    status: "sent",
    documents: [
      {
        name: "Mandatsvertrag.pdf",
        documentBase64: base64String,
        fileExtension: "pdf",
        documentId: "1",
      },
    ],
    recipients: {
      signers: emails.map((email, index) => ({
        email: email,
        recipientId: index + 1,
        name: email,
      })),
    },
  };

  const apiMethod = `${DOCU_SIGN_URL}/accounts/${accountId}/envelopes`;
  try {
    const results = await axios.post(apiMethod, req, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    return results.data.envelopeId;
  } catch (err) {
    return null;
  }
}

export const getUserAccount = async (accessToken) => {
  try {
    const results = await axios.get(INFO_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const accounts = results.data?.accounts ?? [];
    const account = accounts.find((account) => account.is_default);
    return account;
  } catch (err) {
    return null;
  }
}

export const useEnvelopeStatus = (accessToken, accountId, envelopeId) => {
    const [envelopeStatus, setEnvelopeStatus] = useState(null);
    
    useEffect(() => {
      async function updateEnvelopeStatus() {
        if (!accessToken || !envelopeId || !accountId) return;
        const status = await getEnvelopeStatus(accessToken, accountId, envelopeId);

        setEnvelopeStatus(status);
      }
      updateEnvelopeStatus();
    }, [accessToken, envelopeId, accountId]);
    
    return envelopeStatus;    
}

export const getEnvelopeStatus = async (accessToken, accountId, envelopeId) => {
  const apiMethod = `${DOCU_SIGN_URL}/accounts/${accountId}/envelopes/${envelopeId}/recipients`;
  try {
    const results = await axios.get(apiMethod, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return results.data.signers.map(signer => ({
        email: signer.email,
        status: signer.status
    }));
  } catch (err) {
    return null;
  }
};


export async function getSignedDocument(accessToken, accountId, envelopeId) {
  // Make the API call
  const apiMethod = `${DOCU_SIGN_URL}/accounts/${accountId}/envelopes/${envelopeId}/documents/1`;
  const results = await axios.get(apiMethod, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: "blob",
  });
  
  return results;
}

export const useDocuSignAccessToken = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [accountId, setAccountId] = useState(null);

  useEffect(() => {
    async function updateAccessToken() {
      const token = expirableLocalStorage.getItem("docuSignAccessToken");
      if (token) {
        setAccessToken(token);
        const account = await getUserAccount(token)
        setAccountId(account.account_id);
      } else {
        setAccessToken(null);
      }
    }

    updateAccessToken();

    window.addEventListener("storage", updateAccessToken);
    return () => window.removeEventListener("storage", updateAccessToken);
  }, []);

  return [accessToken, accountId];
};

export const authenticateUserForDocuSign = async () => {
  const authRedirectUrl = encodeURI(
    `${window.location.protocol}//${window.location.host}/docuSign/authComplete`
  );
  const authUrl = `${AUTH_URL}/?response_type=token&scope=signature openid cors extended&client_id=${INTEGRATION_KEY}&redirect_uri=${authRedirectUrl}`;
  window.open(authUrl, "DocuSign", "width=800,height=600");
};
