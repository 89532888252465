import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { useCustomers, useInvestors, useTransactions } from "../../elements/frontend/src/hooks";
import { InvestorProps } from "../../elements/frontend/src/Store/interfaces";
import { useEffect, useState } from "react";
import { useGetCategoriesQuery } from "../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";

export function useAllInvestors({ transactionId, setTransaction, transaction }: any) {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const { t } = useTranslation(["investor/common"]);

  const { getInvestors, addInvestor } = useInvestors();
  const { getCustomerById } = useCustomers();
  const { getTransactionById } = useTransactions();

  const [investors, setInvestors] = useState<InvestorProps[] | undefined>(undefined);
  const [loadingInvestors, setLoadingInvestors] = useState<boolean>(true);
  const categoriesQuery = useGetCategoriesQuery();
  const categories = categoriesQuery.data;

  useEffect(() => {
    if (transaction === undefined) {
      const fetchTransaction = async () => {
        const trans = await getTransactionById(transactionId);
        setTransaction(trans.transaction);
      };
      fetchTransaction();
    }
  }, [transactionId, getTransactionById, transaction, setTransaction]);

  useEffect(() => {
    if (investors === undefined && categories?.length && transaction !== undefined) {
      setLoadingInvestors(true);
      getInvestors().then((resp: any) => {
        Promise.all(
          resp.investors.map(async (investor: any) => {
            return await getCustomerById(investor.customer_id).then((data) => {
              const customer = data.customer;

              const investment = customer.metadata.find(
                (meta: any) => meta.scope === "investment"
              )?.data;

              return {
                id: customer.customer_id,
                name: customer.name,
                email: customer.email,
                ebitda: investment?.ebitda || "",
                invest: parseInt(investment?.invest) || "",
                revenue: investment?.revenue || "",
                categories: investment?.categories || [],
                sub_categories: investment?.sub_categories || [],
                period: investment?.period || [],
                phases: investment?.phases || [],
                types: investment?.types || [],
                stake: investment?.stake || [],
                contribution: investment?.contribution || {
                  financial_investor: false,
                  strategic_investor: false,
                  wachstums_investor: false,
                },
              };
            });
          })
        ).then((data: InvestorProps[]) => {
          setInvestors(data);
          setLoadingInvestors(false);
        });
      });
    }
  }, [
    investors,
    categories,
    transaction,
    currentUser.datastore,
    t,
    setInvestors,
    setLoadingInvestors,
    getInvestors,
    getCustomerById,
  ]);

  const addCustomInvestor = (values: any) => {
    const newInvestor = {
      name: values.name,
      email: values.email,
      address: {
        line_1: values.line_1,
        line_2: "",
        city: values.city,
        postal: values.postal,
        country: values.country,
      },
      contact: {
        first_name: "",
        last_name: "",
        telephone: "",
        website: values.website,
      },
      metadata: [
        {
          scope: "investment",
          data: {
            categories: values.categories,
            sub_categories: values.sub_categories,
            phases: values.phases,
            stake: values.stake,
            period: values.period,
            types: values.types,
            invest: values.invest,
            revenue: values.revenue,
            ebitda: values.ebitda,
            contribution: values.contribution,
          },
        },
      ],
    };
    addInvestor(newInvestor);
  };

  return { investors, loadingInvestors, addCustomInvestor };
}
