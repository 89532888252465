import { Card, Section } from "../../../elements/frontend/src/components";
import Stack from "@mui/material/Stack";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingFormValues } from "../interfaces";
import { useGetCountriesQuery } from "../../../elements/frontend/src/Store/api/datastore/countries/countriesApi";
import { usePalturai } from "../../../elements/frontend/src/hooks/usePalturai";
import { useClients } from "../../../elements/frontend/src/hooks";
import { setCurrentUser } from "../../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../../elements/frontend/src/Store/hooks/useAppDispatch";
import OnboardinPopup from "./OnboardinPopup";

export const Company = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["company/common"]);
  const { control, setValue } = useFormContext<OnboardingFormValues>();
  const countriesQuery = useGetCountriesQuery();
  const countries = countriesQuery.data;
  const [trigger, setTrigger] = useState(false);
  const [isGermany, setIsGermany] = useState(false);
  const defaultCountry = control._formValues.country;

  const [inputWebsite, setInputWebsite] = useState("");
  const [isInvalidUrl, setInvalidUrl] = useState(false);

  const { getCompany, getCompanyDetails } = usePalturai();
  const { getClients, updateClient } = useClients();

  const fetchDataAndDetails = async (trade_registration: any, postal: any) => {
    let palturaiId, clientId;

    try {
      // search for company
      const dataResponse = await getCompany(trade_registration, postal);

      if (Array.isArray(dataResponse?.companies) && dataResponse?.companies.length === 1) {
        dispatch(setCurrentUser({ loading: true }));
        setValue("trade_registration", trade_registration);
        setValue("postal", postal);
        setValue("city", dataResponse.companies[0].city);
        setValue("company_name", dataResponse.companies[0].name);
        palturaiId = dataResponse.companies[0].palturai_id;
      }

      // Fetch clients
      const clientsResponse = await getClients();
      if (clientsResponse) {
        clientId = clientsResponse.clients[0].client_id;
      }

      // Update client
      const objToSave = {
        metadata: [
          {
            scope: "palturai",
            data: {
              palturai_id: palturaiId,
            },
          },
        ],
      };
      await updateClient(clientId, objToSave);

      // Fetch company details
      const detailsResponse = await getCompanyDetails();

      if (detailsResponse) {
        setValue("street", detailsResponse.company.address.line_1);
        setValue("website", detailsResponse.company.contact.websites[0]);
        setValue("no_bankruptcy", !detailsResponse.company.insolvent);
        setValue("legal_form", detailsResponse.company.legal_form);
        setValue("nace_code", detailsResponse.company.industries.join("   "));
        dispatch(setCurrentUser({ loading: false }));
      }
    } catch (error) {
      setValue("trade_registration", trade_registration);
      setValue("postal", postal);
    }
  };

  // Country options for select "Land"
  const countriesData: { [key: string]: string } = t("company/common:countries_data", {
    returnObjects: true,
  });

  interface countriesDataArrProps {
    code: string;
    name: string;
  }

  const countriesDataArr: countriesDataArrProps[] = Object.keys(countriesData).map((code) => ({
    code,
    name: countriesData[code],
  }));

  const sortCountries = countriesDataArr.slice().sort((a, b) => a.name.localeCompare(b.name));

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;

    if (value) {
      setValue("trade_registration", "HRB-");
    } else {
      setValue("trade_registration", "");
    }
    value === "DEU" ? setIsGermany(true) : setIsGermany(false);
    setValue("country", value);
  };

  //checking the valid input website
  function isValidUrl(val: any) {
    const pattern = new RegExp(
      "^(https?://)?(www\\.)?[a-z\\d]([a-z\\d-]*[a-z\\d]*)\\.(com|de|net|org|eu)(/[\\w.-]*)?$",
      "i"
    );
    return pattern.test(val);
  }

  useEffect(() => {
    const checkUrl = isValidUrl(inputWebsite);
    if (inputWebsite.length === 0) {
      setInvalidUrl(true);
    } else {
      setInvalidUrl(checkUrl);
    }
  }, [inputWebsite]);

  useEffect(() => {
    const checkUrl = isValidUrl(inputWebsite);
    if (inputWebsite.length === 0) {
      setInvalidUrl(true);
    } else {
      setInvalidUrl(checkUrl);
    }
  }, [inputWebsite]);

  const handelPostal = () => {
    if (defaultCountry === "DEU") setIsGermany(true);
    else setIsGermany(false);
  };
  return (
    <>
      <OnboardinPopup fetchDataAndDetails={fetchDataAndDetails} intialValue={control} />
      {/* @ts-ignore */}
      <Section title={t("company/common:onboarding.company.headline")}>
        <Card>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mb: 2 }}>
            <Controller
              name="trade_registration"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  name="trade_registration"
                  label={t("company/common:onboarding.profile.trade_registration")}
                  value={value}
                  size="small"
                  required
                  fullWidth
                  onChange={onChange}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-trade_registration",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />

            <Controller
              name="postal"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  name="postal"
                  label={t("company/common:onboarding.company.postal_code")}
                  value={isGermany ? value.substring(0, 5) : value}
                  size="small"
                  required
                  fullWidth
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    onChange(numericValue);
                    fetchDataAndDetails(
                      control._formValues.trade_registration,
                      control._formValues.postal
                    );
                  }}
                  onClick={handelPostal}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-postal",
                    form: {
                      autoComplete: "off",
                    },
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: isGermany ? 5 : undefined,
                  }}
                />
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
            <Controller
              name="company_name"
              control={control}
              render={({ fieldState: { error }, field: { onChange, value } }) => (
                <TextField
                  name="company_name"
                  label={t("company/common:onboarding.company.company_name")}
                  value={value}
                  size="small"
                  fullWidth
                  required
                  onChange={onChange}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-company",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />

            <Controller
              name="street"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  name="street"
                  label={t("company/common:onboarding.company.street")}
                  value={value}
                  size="small"
                  required
                  fullWidth
                  onChange={onChange}
                  onBlur={() => {
                    const containNumAndText = /[a-zA-Z]/.test(value) && /\d/.test(value);
                    if (!containNumAndText) {
                      setTrigger(true);
                    } else {
                      setTrigger(false);
                    }
                  }}
                  error={!!error || trigger}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-street",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            <Controller
              name="city"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  name="city"
                  label={t("company/common:onboarding.company.city")}
                  value={value}
                  size="small"
                  required
                  fullWidth
                  onChange={onChange}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "new-city",
                    form: {
                      autoComplete: "off",
                    },
                    onInput: (e) => {
                      e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z\s]/g, "");
                    },
                  }}
                />
              )}
            />
            <Controller
              name="country"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <>
                  <FormControl fullWidth size="small">
                    <InputLabel id="country" required>
                      {t("company/common:onboarding.company.country")}
                    </InputLabel>
                    <Select
                      name="country"
                      labelId="country"
                      value={value}
                      label={t("company/common:onboarding.company.country")}
                      onChange={handleCountryChange}
                      error={!!error}
                      MenuProps={{
                        PaperProps: {
                          style: { marginTop: "0.1%", maxHeight: "85vh" },
                        },
                      }}
                    >
                      {sortCountries.map((sortCountry) => (
                        <MenuItem key={sortCountry.code} value={sortCountry.code}>
                          {countries
                            ?.filter((country) => country.value === sortCountry.code)
                            .map((country) => (
                              <div key={country.value}>{sortCountry.name}</div>
                            ))}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!error?.message && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                </>
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 2 }}>
            <Controller
              name="website"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  name="website"
                  label={t("company/common:onboarding.company.website")}
                  value={value}
                  size="small"
                  fullWidth
                  placeholder="www.example.com"
                  onChange={onChange}
                  error={!!error && !isInvalidUrl}
                  helperText={
                    !isInvalidUrl ? t("company/common:onboarding.company.website_invalid_url") : ""
                  }
                  inputProps={{
                    autoComplete: "new-website",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                  onBlur={(e) => {
                    const urlVal = e.target.value;
                    setInputWebsite(urlVal);
                  }}
                />
              )}
            />
          </Stack>
        </Card>
      </Section>
    </>
  );
};
