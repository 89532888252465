import { useState, useEffect } from "react";
import { useClients } from "../../elements/frontend/src/hooks";
import { usePalturai } from "../../elements/frontend/src/hooks/usePalturai";

const updatePaturaiId = async (currentUser, getClients, getCompany, updateClient) => {
  let palturaiId, clientId;
  let trade_registration = currentUser?.customer.metadata.find((item) => item.scope === "profile")
    ?.data.trade_registration;
  let postal = currentUser?.customer.address.postal;

  try {
    const dataResponse = await getCompany(trade_registration, postal);
    if (Array.isArray(dataResponse?.companies) && dataResponse?.companies.length === 1) {
      palturaiId = dataResponse.companies[0].palturai_id;
    }
    const clientsResponse = await getClients();
    if (clientsResponse) {
      clientId = clientsResponse.clients[0].client_id;
    }

    const objToSave = {
      metadata: [
        {
          scope: "palturai",
          data: {
            palturai_id: palturaiId,
          },
        },
      ],
    };
    await updateClient(clientId, objToSave);
  } catch (error) {}
};

const fetchpalturiDeails = async (getCompanyDetails, setDetailsCompany) => {
  try {
    const detailsResponse = await getCompanyDetails();
    if (detailsResponse) {
      setDetailsCompany(detailsResponse);
    }
  } catch (error) {}
};

export const Datahooks = (currentUser) => {
  const { getClients, updateClient } = useClients();
  const { getCompany, getCompanyDetails } = usePalturai();
  const [detailsCompany, setDetailsCompany] = useState();

  useEffect(() => {
    updatePaturaiId(currentUser, getClients, getCompany, updateClient).then(() =>
      fetchpalturiDeails(getCompanyDetails, setDetailsCompany)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    detailsCompany,
  };
};
