import { Card, CategoriesDropdown, Section } from "../../../elements/frontend/src/components";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormHelperText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { OnboardingFormValues } from "../interfaces";
import { useAppSelector } from "../../../elements/frontend/src/Store/hooks/useAppSelector";
import TextField from "@mui/material/TextField";
import {
  CategoryOption,
  CategoryType,
} from "../../../elements/frontend/src/components/CategoriesDropdown/interfaces";
import { useGetCategoryTreesQuery } from "../../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";
import { removeAllChildSubCategoriesWithoutSelectedRootCategory } from "../../../elements/frontend/src/components/CategoriesDropdown/CategoriesDropdown.utils";

interface ProfileProps {
  initial: boolean;
  setInitial: (value: boolean) => void;
}
export const Profile: React.FC<ProfileProps> = ({ initial, setInitial }) => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  const categoryTreesQuery = useGetCategoryTreesQuery();
  const categoryTrees = categoryTreesQuery.data;

  const { t } = useTranslation(["company/common"]);
  const { control, watch, setValue } = useFormContext<OnboardingFormValues>();
  const categoryIds = watch("categories");
  const subCategoryIds = watch("subCategories");

  const handleCategoriesSelect = (categories: CategoryOption[], type: CategoryType) => {
    const categoryIds = categories.map((category) => category.value);

    if (type === CategoryType.CATEGORY && categoryTrees) {
      setValue("categories", categoryIds);
      const updatedSubCategoryIds = removeAllChildSubCategoriesWithoutSelectedRootCategory(
        categoryIds,
        subCategoryIds,
        categoryTrees
      );
      setValue("subCategories", updatedSubCategoryIds);
    }

    if (type === CategoryType.SUB_CATEGORY) {
      setValue("subCategories", categoryIds);
    }
  };
  return (
    <>
      {/* @ts-ignore */}
      <Section title={t("company/common:onboarding.profile.headline")}>
        <Card>
          <Controller
            name="categories"
            control={control}
            render={({ fieldState: { error } }) => (
              <CategoriesDropdown
                selectedCategoryIds={categoryIds}
                handleCategoriesSelect={handleCategoriesSelect}
                selectedSubCategoryIds={subCategoryIds}
                withSubcategories={true}
                initial={initial}
                setInitial={setInitial}
              />
            )}
          />
          <Stack sx={{ mt: 2 }}>
            <Controller
              name="nace_code"
              control={control}
              render={({ fieldState: { error }, field: { value, onChange } }) => (
                <TextField
                  name="nace_code"
                  label={t("company/common:onboarding.profile.nace_code")}
                  value={value}
                  size="small"
                  fullWidth
                  onChange={onChange}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputProps={{
                    autoComplete: "nace_code",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mt: 2 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="legal_form-label" required>
                {t("company/common:onboarding.profile.legal_form")}
              </InputLabel>

              <Controller
                name="legal_form"
                control={control}
                render={({ fieldState: { error }, field: { value, onChange } }) => (
                  <>
                    <Select
                      name="legal_form"
                      labelId="legal_form-label"
                      value={value}
                      label={t("company/common:onboarding.profile.legal_form")}
                      onChange={onChange}
                      error={!!error?.message}
                    >
                      <MenuItem value={value}>{value}</MenuItem>
                      {currentUser.datastore.legal_form &&
                        currentUser.datastore.legal_form.map(({ text, value, key }: any) => (
                          <MenuItem value={value} key={key}>
                            {text}
                          </MenuItem>
                        ))}
                    </Select>
                    {!!error?.message && (
                      <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="fiscal_year-label">
                {t("company/common:onboarding.profile.fiscal_year")}
              </InputLabel>

              <Controller
                name="fiscal_year"
                control={control}
                render={({ fieldState: { error }, field: { value, onChange } }) => (
                  <>
                    <Select
                      labelId="fiscal_year-label"
                      value={value} // to fix the warning related MUI [Select] You have provided an out-of-range value
                      label={t("company/common:onboarding.profile.fiscal_year")}
                      onChange={onChange}
                      error={!!error?.message}
                    >
                      {currentUser.datastore.fiscal_year &&
                        currentUser.datastore.fiscal_year.map(({ value, text, key }: any) => (
                          <MenuItem value={value} key={key}>
                            {text}
                          </MenuItem>
                        ))}
                    </Select>
                    {!!error?.message && (
                      <FormHelperText>{t("misc.required_field")}</FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>
          </Stack>
        </Card>
      </Section>
    </>
  );
};
