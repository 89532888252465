import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { useClients } from "../../elements/frontend/src/hooks";
import PersonalTalk from "../../components/PersonalTalk";
// import ClientAgreement from "../../components/ClientAgreement";
import { DashbordContext } from "../../views/Private/Home";
import { DashboardDispatch } from "../../views/Private/Home";

import { Wrapper } from "./styled.content";
import TransactionCheck from "../../components/TransactionCheck";
import { Onboarding } from "../../components/Onboarding/Onboarding";
import { DataImport } from "../../components/DatenImport/DataImport";
import ClientAgreement from "../../components/ClientAgreement";
import { Details } from "../../components/Details/Details";
import { DueDiligence } from "../../elements/frontend/src/components/DueDiligence/DueDiligence";
import { useParams } from "react-router-dom";
import { Financial } from "../../elements/frontend/src/components/DueDiligence/components/Financial";
import { Tax } from "../../elements/frontend/src/components/DueDiligence/components/Tax";
import { CommercialCompany } from "../../elements/frontend/src/components/DueDiligence/components/Commercial/CommercialCompany";
import InvestorMatching from "../../components/InvestorMatching/InvestorMatching";
import { InvestorStatusTable } from "../../elements/frontend/src/components/InvestorStatusTable/InvestorStatusTable";

const Content = () => {
  const { updateClient } = useClients();
  const params = useParams();
  const hasParams = Object.keys(params).length > 0;
  const step = params.step;
  const dashboardContext = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);

  const currentUser = useSelector(({ currentUser }) => currentUser);

  const selectedClientId = currentUser.selectedClient.client_id;

  // for the self_registered company, check the state of transaction, If "personal talk" has completed, then change dashboard_currentStep = "TRANSACTION_CHECK"
  useEffect(() => {
    if (dashboardContext.isAdvisorClient) return;
    if (
      !dashboardContext.isAdvisorClient &&
      dashboardContext?.transactions[0]?.state?.stages?.onboarding_call?.completed &&
      dashboardContext.dashboard_lastStep === "PERSONAL_TALK"
    ) {
      const lastStep =
        dashboardContext.dashboard_lastStep === "PERSONAL_TALK"
          ? "TRANSACTION_CHECK"
          : dashboardContext.dashboard_lastStep;
      const currentStep =
        dashboardContext.dashboard_lastStep === "PERSONAL_TALK"
          ? "TRANSACTION_CHECK"
          : dashboardContext.dashboard_lastStep;
      const completedSteps =
        dashboardContext.dashboard_lastStep === "PERSONAL_TALK"
          ? [...dashboardContext.dashboard_completedSteps, "PERSONAL_TALK"]
          : dashboardContext.dashboard_completedSteps;

      // object to update in client DB
      const objClientData = {
        metadata: [
          {
            scope: "profile",
            data: {
              dashboard_currentStep: currentStep,
              dashboard_lastStep: lastStep,
              dashboard_completedSteps: completedSteps,
            },
          },
        ],
      };

      // update client data in DB
      updateClient(selectedClientId, objClientData)
        .then((response) => {
          if (response) {
            // update dashboard steps in dashboardContext
            dashboardDispatch({
              type: "UPDATE_DATA",
              payload: {
                dashboard_currentStep: currentStep,
                dashboard_lastStep: lastStep,
                dashboard_completedSteps: completedSteps,
              },
            });
          }
        })
        .catch(() => {});
    }
  }, [
    dashboardContext.dashboard_completedSteps,
    dashboardContext.dashboard_lastStep,
    dashboardContext.isAdvisorClient,
    dashboardContext?.transactions,
    dashboardDispatch,
    selectedClientId,
    updateClient,
  ]);

  // for the advisor clients, check the state of transaction, If "onboarding" has completed, then change dashboard_currentStep = "DETAILS"

  useEffect(() => {
    if (!dashboardContext.isAdvisorClient) return;
    if (
      dashboardContext.isAdvisorClient &&
      dashboardContext?.transactions[0]?.state?.stages?.onboarding?.completed &&
      dashboardContext.dashboard_lastStep === "CLIENT_AGREEMENT"
    ) {
      const lastStep =
        dashboardContext.dashboard_lastStep === "CLIENT_AGREEMENT"
          ? "DETAILS"
          : dashboardContext.dashboard_lastStep;
      const currentStep =
        dashboardContext.dashboard_lastStep === "CLIENT_AGREEMENT"
          ? "DETAILS"
          : dashboardContext.dashboard_lastStep;
      const completedSteps =
        dashboardContext.dashboard_lastStep === "CLIENT_AGREEMENT"
          ? [...dashboardContext.dashboard_completedSteps, "CLIENT_AGREEMENT"]
          : dashboardContext.dashboard_completedSteps;

      // object to update in client DB
      const objClientData = {
        metadata: [
          {
            scope: "profile",
            data: {
              dashboard_currentStep: currentStep,
              dashboard_lastStep: lastStep,
              dashboard_completedSteps: completedSteps,
            },
          },
        ],
      };

      // update client data in DB
      updateClient(selectedClientId, objClientData)
        .then((response) => {
          if (response) {
            // update dashboard steps in dashboardContext
            dashboardDispatch({
              type: "UPDATE_DATA",
              payload: {
                dashboard_currentStep: currentStep,
                dashboard_lastStep: lastStep,
                dashboard_completedSteps: completedSteps,
              },
            });
          }
        })
        .catch(() => {});
    }
  }, [
    dashboardContext.dashboard_completedSteps,
    dashboardContext.dashboard_lastStep,
    dashboardContext.isAdvisorClient,
    dashboardContext?.transactions,
    dashboardDispatch,
    selectedClientId,
    updateClient,
  ]);

  return (
    <Wrapper>
      {dashboardContext.dashboard_currentStep === "ONBOARDING" && <Onboarding />}
      {dashboardContext.dashboard_currentStep === "DATEN_IMPORT" && <DataImport />}
      {dashboardContext.dashboard_currentStep === "PERSONAL_TALK" && <PersonalTalk />}
      {dashboardContext.dashboard_currentStep === "TRANSACTION_CHECK" && <TransactionCheck />}
      {dashboardContext.dashboard_currentStep === "CLIENT_AGREEMENT" && <ClientAgreement />}
      {dashboardContext.dashboard_currentStep === "DETAILS" && hasParams && step === "details" && (
        <Details />
      )}
      {dashboardContext.dashboard_currentStep === "DETAILS" &&
        hasParams &&
        step === "due-diligence-legal" && <DueDiligence />}
      {dashboardContext.dashboard_currentStep === "DETAILS" &&
        hasParams &&
        step === "due-diligence-financial" && <Financial />}
      {dashboardContext.dashboard_currentStep === "DETAILS" &&
        hasParams &&
        step === "due-diligence-commercial" && <CommercialCompany />}
      {dashboardContext.dashboard_currentStep === "DETAILS" &&
        hasParams &&
        step === "due-diligence-tax" && <Tax />}
      {dashboardContext.dashboard_currentStep === "DETAILS" &&
        hasParams &&
        step === "investment-documents" && <DueDiligence />}
      {dashboardContext.dashboard_currentStep === "DETAILS" &&
        hasParams &&
        step === "investor-matching" && <InvestorMatching />}
      {dashboardContext.dashboard_currentStep === "DETAILS" &&
        hasParams &&
        step === "investor-status" && <InvestorStatusTable />}
    </Wrapper>
  );
};

export default Content;
/* 
      {dashboardContext.dashboard_currentStep === "DETAILS" && <Details />}
      {hasParams && step === "due-diligence" && <DueDiligence />}
      {hasParams && step === "investment-documents" && <DueDiligence />}
*/
