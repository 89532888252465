import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Section } from "../../elements/frontend/src/components/Section/Section";
import InvestorInvite from "../../elements/frontend/src/components/InvestorMatching/InvestorInvite/InvestorInvite";
import InvestorTable from "../../elements/frontend/src/components/InvestorTable/InvestorTable";
import useInvestorMatching from "./useInvestorMatching";
import CustomInvestorForm from "../../elements/frontend/src/components/InvestorMatching/CustomInvestorForm/CustomInvestorForm";
import { useInvestmentTeaser } from "../../elements/frontend/src/hooks/useInvestmentTeaser";
import { InvestorProps } from "../../elements/frontend/src/Store/interfaces";
import InvestorAnalyticsPoupup from "../../elements/frontend/src/components/InvestorTable/InvestorAnalyticsPoupup";
import { Card, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ActionMenu from "../../elements/frontend/src/components/ActionMenu/ActionMenu";
import InvestorTablePopup from "../../elements/frontend/src/components/InvestorTable/InvestorTablePopup";
import { InvestmentTeaser } from "../../elements/frontend/src/components/InvestorTable/InvestmentTeaser";
import InvestmentDossier from "../../elements/frontend/src/components/InvestorTable/InvestmentDossier";

const useDisabler = (transactionId: any) => {
  const [disabled, setDisabled] = useState(false);
  const { getPuplicFile } = useInvestmentTeaser();

  useEffect(() => {
    const getFile = async () => {
      const file = await getPuplicFile(transactionId);
      if (file.files.length === 0) {
        setDisabled(true);
      }
    };

    getFile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { disabled };
};

const InvestorMatching = () => {
  const {
    investors,
    loadingInvestors,
    invitations,
    loadingInvitations,
    isInvited,
    isWhiteListed,
    expandCategories,
    setExpandCategories,
    addCustomInvestor,
    inviteInvestor,
    unInviteInveestor,
  } = useInvestorMatching();

  const { transactionId } = useParams();
  const { disabled } = useDisabler(transactionId);

  const isinvited = (investor: InvestorProps) => {
    return invitations.some((inv) => inv.id === investor.id);
  };

  const actions_table_2 = useCallback(
    (investor: InvestorProps, disabled: boolean) => {
      return [
        {
          title: "Investment Teaser",
          element: (
            <IconButton size="medium">
              <InvestorTablePopup investor={investor} disabled={disabled} />
            </IconButton>
          ),
        },
        {
          title: "Vorschau",
          element: (
            <IconButton size="medium" sx={{ textAlign: "center" }}>
              <InvestorAnalyticsPoupup investor={investor} disabled={disabled} />
            </IconButton>
          ),
        },
        {
          title: "Entfernen",
          element: (
            <IconButton
              size="medium"
              disabled={!isWhiteListed(investor.id)}
              onClick={() => unInviteInveestor(investor)}
            >
              <ClearIcon />
            </IconButton>
          ),
        },
      ];
    },
    [isWhiteListed, unInviteInveestor]
  );

  return (
    <Section title={"Investoren"}>
      <Card
        style={{
          maxWidth: "95%",
          marginLeft: "25px",
        }}
      >
        <InvestorTable
          disabled={disabled}
          investors={investors?.filter((inv: any) => !isinvited(inv))}
          loading={loadingInvestors}
          expandCategories={expandCategories}
          setExpandCategories={setExpandCategories}
          renderActions={(investor: any) => {
            return (
              <div>
                {
                  <IconButton size="medium" onClick={() => inviteInvestor(investor)}>
                    <AddIcon />
                  </IconButton>
                }
              </div>
            );
          }}
        />
        <CustomInvestorForm addCustomInvestor={addCustomInvestor} />
      </Card>
      <Card
        style={{
          maxWidth: "95%",
          marginLeft: "25px",
        }}
      >
        <InvestorInvite
          invitations={invitations}
          investors={investors}
          loadingInvitations={loadingInvitations}
          isInvited={isInvited}
          expandCategories={expandCategories}
          setExpandCategories={setExpandCategories}
          disabled={disabled}
          unInviteInveestor={unInviteInveestor}
          renderActions={(investor: InvestorProps, disabled: boolean) => {
            return <ActionMenu buttons={actions_table_2(investor, disabled)} />;
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InvestmentTeaser disabled={disabled} />
          <InvestmentDossier />
        </div>
      </Card>
      <div
        style={{
          width: "fit-content",
          marginLeft: "25px",
        }}
      ></div>
    </Section>
  );
};
export default InvestorMatching;
