import { API } from "aws-amplify";
import { getCovid } from "../common";
import TConfig from "../../../../config";

export const usePalturai = () => {
  const getCompany = (registration, postal) => {
    return API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.PALTURAI_COMPANY_SEARCH_API_ENDPOINT}`,
      {
        queryStringParameters: {
          registration: registration,
          postal: postal,
          cov_id: getCovid(),
        },
      }
    );
  };

  const getCompanyDetails = () => {
    return API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.PALTURAI_COMPANY_DETAILS_API_ENDPOINT}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
      }
    );
  };

  return {
    getCompany,
    getCompanyDetails,
  };
};
