import { Card, Section } from "../../../elements/frontend/src/components";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { InfoWrapper } from "../styled.onboarding";
import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { OnboardingFormValues } from "../interfaces";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const Interest = () => {
  const { t } = useTranslation(["company/common"]);
  const { control } = useFormContext<OnboardingFormValues>();
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  return (
    <>
      {/* @ts-ignore */}
      <Section title={t("company/common:onboarding.interest.headline")}>
        <Card>
          <Stack>
            <Controller
              name="no_bankruptcy"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      value="no_bankruptcy"
                      color="primary"
                      size={isMobile ? "small" : "medium"}
                      checked={value}
                    />
                  }
                  label={
                    <InfoWrapper>
                      {t("company/common:onboarding.interest.no_bankruptcy")}{" "}
                      <span
                        className="info_icon"
                        data-hover={t(
                          "company/common:onboarding.interest.info_icons.no_bankruptcy"
                        )}
                      >
                        i
                      </span>
                    </InfoWrapper>
                  }
                  onChange={onChange}
                  sx={{ width: "fit-content" }}
                />
              )}
            />

            <Controller
              name="no_project_business"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      value="no_project_business"
                      color="primary"
                      size={isMobile ? "small" : "medium"}
                      checked={value}
                    />
                  }
                  label={
                    <InfoWrapper>
                      {t("company/common:onboarding.interest.no_project_business")}{" "}
                      <span
                        className="info_icon"
                        data-hover={t(
                          "company/common:onboarding.interest.info_icons.no_project_business"
                        )}
                      >
                        i
                      </span>
                    </InfoWrapper>
                  }
                  onChange={onChange}
                  sx={{ width: "fit-content" }}
                />
              )}
            />
          </Stack>
        </Card>
      </Section>
    </>
  );
};
