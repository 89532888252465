import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from "react-table";

import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/Delete";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
import { useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAppSelector } from "../../../Store/hooks/useAppSelector";

import { CustomTooltip, LoadingIndicator } from "../../";

import { fuzzyTextFilterFn, DefaultColumnFilter, GlobalFilter } from "../helper";
import {
  Wrapper,
  StyledTableCell,
  StyledTableRow,
  StyledTextField,
  StyledSelect,
} from "./styled.tableComp";

export const DocumentsListTable = ({
  columns,
  data,
  handleUpdateFile = () => {},
  handleDownloadFile = () => {},
  handleDeleteFile = () => {},
  handleFileView = () => {},
  disableButton = false,
  hideButtons,
  dd,
}) => {
  const { t } = useTranslation(["platform/common"]);

  const [filesData, setFilesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const transactionId = params.transactionId;
  //const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const productId = "platform"

  // update state transactionData with props data
  useEffect(() => {
    if (data) {
      setFilesData(data);
      setLoading(false);
    }
  }, [data]);

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    prepareRow,
    // below new props related to 'usePagination' hook
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: filesData,
      initialState: { pageIndex: 0, pageSize: 10 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!  We need to place useFilters before useSortBy.
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );

  return (
    <>
      {loading && <LoadingIndicator type={"COMPONENT"} />}
      <Wrapper>
        <Table {...getTableProps()} size="small">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                    <div onClick={(e) => e.stopPropagation()}>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
                <th
                  style={{
                    textAlign: "center",
                  }}
                >
                  {t("transaction_tables.columns.action")}
                </th>
              </TableRow>
            ))}
            <TableRow>
              <th
                colSpan={visibleColumns.length + 1}
                style={{
                  textAlign: "left",
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </TableRow>
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <StyledTableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </StyledTableCell>
                    );
                  })}
                  <StyledTableCell sx={{ textAlign: "center" }}>
                    {!hideButtons && (
                      <>
                        {dd && productId !== "company" && (
                          <CustomTooltip
                            title={t("transaction_tables.deal_documents.view_file")}
                            placement="top"
                            arrow
                          >
                            <span>
                              <IconButton
                                size="small"
                                onClick={() => handleFileView(row.original.file_id, transactionId)}
                                disabled={disableButton}
                              >
                                <VisibilityIcon color="primary" fontSize="small" />
                              </IconButton>
                            </span>
                          </CustomTooltip>
                        )}

                        <CustomTooltip
                          title={t("transaction_tables.deal_documents.update_file")}
                          placement="top"
                          arrow
                        >
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => handleUpdateFile(row.original)}
                              disabled={disableButton}
                            >
                              <CloudUploadSharpIcon color="primary" fontSize="small" />
                            </IconButton>
                          </span>
                        </CustomTooltip>

                        <CustomTooltip
                          title={t("transaction_tables.deal_documents.download_file")}
                          placement="top"
                          arrow
                        >
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => handleDownloadFile(row.original.file_id)}
                              disabled={disableButton}
                            >
                              <SaveAltIcon color="primary" fontSize="small" />
                            </IconButton>
                          </span>
                        </CustomTooltip>

                        <CustomTooltip
                          title={t("transaction_tables.deal_documents.delete_file")}
                          placement="top"
                          arrow
                        >
                          <span>
                            <IconButton
                              size="small"
                              onClick={() => handleDeleteFile(row.original.file_id)}
                              disabled={disableButton}
                            >
                              <Delete color="primary" fontSize="small" />
                            </IconButton>
                          </span>
                        </CustomTooltip>
                      </>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          sx={{ marginTop: "10px" }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              gotoPage(0);
            }}
            disabled={!canPreviousPage}
            sx={{ minWidth: "35px", padding: "5px" }}
          >
            {"<<"}
          </Button>

          <Button
            size="small"
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              previousPage();
            }}
            disabled={!canPreviousPage}
            sx={{ minWidth: "35px", padding: "5px" }}
          >
            {"<"}
          </Button>

          <Button
            size="small"
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              nextPage();
            }}
            disabled={!canNextPage}
            sx={{ minWidth: "35px", padding: "5px" }}
          >
            {">"}
          </Button>

          <Button
            size="small"
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              gotoPage(pageCount - 1);
            }}
            disabled={!canNextPage}
            sx={{ minWidth: "35px", padding: "5px" }}
          >
            {">>"}
          </Button>

          <span style={{ fontSize: "15px" }}>
            {t("transaction_tables.pagination.page")}
            <strong>
              {pageIndex + 1} {t("transaction_tables.pagination.of")} {pageOptions.length}
            </strong>
          </span>
          <span style={{ fontSize: "15px" }}>
            |  {t("transaction_tables.pagination.go_to_page")}
          </span>
          <StyledTextField
            defaultValue={pageIndex + 1}
            type="number"
            onChange={(e) => {
              e.preventDefault();
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            sx={{ width: "70px" }}
          />
          <StyledSelect
            size="small"
            value={pageSize}
            onChange={(e) => {
              e.preventDefault();
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize, i) => (
              <MenuItem key={i} value={pageSize}>
                {t("transaction_tables.pagination.show")}  {pageSize}
              </MenuItem>
            ))}
          </StyledSelect>
        </Stack>
      </Wrapper>
    </>
  );
};
