import { styled } from "@mui/material/styles";

export const InfoWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  // overflow: "hidden",

  "& > span.info_icon": {
    position: "relative",
    display: "flex",
    maxWidth: "15px",
    minWidth: "15px",
    height: "15px",
    justifyContent: "center",
    alignItems: "center",
    border: `1.8px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    color: theme.palette.primary.main,
    fontSize: "11px",
    padding: "2px",
    marginLeft: "10px",
    transition: "color 0.5s",
    [theme.breakpoints.up("md")]: {
      marginLeft: "15px",
    },

    "&:after": {
      content: "attr(data-hover)",
      display: "none",
      zIndex: "10000000",
      position: "absolute",
      bottom: "20px",
      left: "-200px",
      width: "250px",
      height: "auto",
      fontSize: "0.85rem",
      borderRadius: "10px",
      padding: "15px",
      lineHeight: "1.6",
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[50],
      boxShadow: `-1px -1px 3px ${theme.palette.grey[300]}, 1px 1px 3px ${theme.palette.grey[300]}`,
      [theme.breakpoints.up("md")]: {
        width: "300px",
        bottom: "20px",
        left: "-150px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "400px",
        bottom: "-20px",
        left: "30px",
      },
    },
    "&:hover": {
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      cursor: "pointer",
    },
    "&:hover::after": { display: "inline-block" },
  },
}));
