import React, { useEffect, useMemo, useState } from "react";
import {
  Stack,
  Checkbox,
  useMediaQuery,
  FormControlLabel,
  Typography,
  Collapse,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { DocumentsTable } from "../..";
import { Messenger } from "../../DDMessenger/Messenger";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../Store/hooks/useAppSelector";
//import { API } from "aws-amplify";
//import { getCovid } from "../../../common";
//import TConfig from "../../../../../../config";
//import { fi } from "date-fns/locale";

const DDAccordion = ({ name, data, handleChange, expanded, setExpanded, ddFiles, metaData1 }) => {
  const { t } = useTranslation(["company/due_dilligence"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const params = useParams();
  const transactionId = params.transactionId;
  const [filesObj, setFilesObj] = useState({});
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const productId = currentUser?.customer?.subscription?.product_id;

  useEffect(() => {
    if (!ddFiles) return;
    for (const keyName in data) {
      const files = ddFiles.filter((file) => file.dd_section === keyName);
      setFilesObj((prev) => {
        return {
          ...prev,
          [keyName]: files,
        };
      });
    }
  }, [data, ddFiles]);

  const calculateProgress = useMemo(() => {
    const stepCount = Object.keys(data).length;
    let filledStepCount = 0;
    let completedStepCount = 0;
    Object.keys(data).map((step) => {
      if (data[step].not_relevant === true || (filesObj[step]?.length ?? 0) > 0) {
        filledStepCount++;
      }
      if (data[step].completed) {
        completedStepCount++;
      }
      return null;
    });
    return (
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        gap="40px"
        width="100%"
        color="white"
        fontWeight="bold"
      >
        <Stack flexDirection="row" gap="7px">
          <Typography>{t(`company/due_dilligence:misc.filled_out`) + ": "}</Typography>
          <Typography width="40px" textAlign="left">
            {stepCount === filledStepCount ? (
              <DoneIcon color="white" sx={{ display: "flex" }} fontSize="medium" />
            ) : (
              `${filledStepCount} / ${stepCount}`
            )}
          </Typography>
        </Stack>
        <Stack flexDirection="row" gap="7px">
          <Typography>{t(`company/due_dilligence:misc.completed`) + ": "}</Typography>

          <Typography width="40px" textAlign="left">
            {stepCount === completedStepCount ? (
              <DoneIcon color="white" sx={{ display: "flex" }} fontSize="medium" />
            ) : (
              `${completedStepCount} / ${stepCount}`
            )}
          </Typography>
        </Stack>
      </Stack>
    );
  }, [data, t, filesObj]);

  return (
    <Box sx={{ marginBottom: "2px", fontWeight: "bold" }} key={name}>
      <Box
        color="primary"
        sx={{
          backgroundColor: theme.palette.blue.main,
          width: "100%",
          padding: "15px",
          "&:hover": {
            cursor: "pointer",
          },
        }}
        id={name}
        onClick={(e) => {
          e.preventDefault();
          if (name === expanded) {
            setExpanded("0");
          } else {
            setExpanded(name);
          }
        }}
      >
        <Stack
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="title" color="white" className="textLabel">
            {name.split("").slice(0, -1).join("") +
              " " +
              t(`company/due_dilligence:headings.${name}.label`)}
          </Typography>
          <Typography>{data ? calculateProgress : ""}</Typography>
        </Stack>
      </Box>
      <Collapse
        orientation="vertical"
        collapsedSize={0}
        in={expanded === name}
        sx={{
          backgroundColor: theme.palette.grey[300],
        }}
      >
        {Object.keys(data).map((keyName) => {
          return (
            <Stack margin="20px">
              <Stack key={keyName}>
                {keyName + " " + t(`company/due_dilligence:headings.${name}.${keyName}`)}
                <Stack flexDirection="row" justifyContent="space-between">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="not_relevant"
                        color="primary"
                        size={isMobile ? "small" : "medium"}
                        checked={data[keyName].not_relevant ? true : false}
                      />
                    }
                    label={t("company/due_dilligence:misc.not_relevant")}
                    onClick={(e) => handleChange(name, keyName, e.target.name, e.target.checked)}
                    sx={{ width: "fit-content" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="completed"
                        color="primary"
                        size={isMobile ? "small" : "medium"}
                        checked={data[keyName].completed ? true : false}
                      />
                    }
                    label={t("company/due_dilligence:misc.completed")}
                    onClick={(e) => handleChange(name, keyName, e.target.name, e.target.checked)}
                    sx={{ width: "fit-content" }}
                  />
                </Stack>
              </Stack>

              <Stack
                sx={{
                  backgroundColor: "#fefefe",
                  marginTop: "16px",
                  padding: "3px",
                  borderRadius: "3px",
                }}
              >
                <Messenger
                  name={name}
                  keyName={keyName}
                  handleChange={handleChange}
                  comments={data[keyName].comments}
                  transactionId={transactionId}
                  productId={productId}
                  parentExpanded={expanded}
                />
              </Stack>
              <Stack
                sx={{
                  backgroundColor: "#fefefe",
                  padding: "10px 15px",
                  marginTop: "15px",
                  borderRadius: "3px",
                }}
              >
                <DocumentsTable
                  originalFiles={filesObj[keyName]}
                  product={productId}
                  page={"due_diligence"}
                  name={name}
                  keyName={keyName}
                  handleDDUpdate={handleChange}
                  setFilesObj={setFilesObj}
                />
              </Stack>
            </Stack>
          );
        })}
      </Collapse>
    </Box>
  );
};

export default DDAccordion;
