// import { useDispatch, useSelector } from "react-redux";

import { StyledDashboardLayout } from "./styled.dashboard";
// import { useSystemMessages } from "../hooks/useSystemMessages";

export const DashboardLayout = ({ children }) => {
  // const { themeOptions, currentUser, systemMessages } = useSelector((state: ILocalState) => state);
  // const { removeSystemMessage } = useSystemMessages();
  // const dispatch = useDispatch<Dispatch<IThemeOptionsAction>>();

  return (
    <StyledDashboardLayout>
      {children}
      {/* <SystemMessages messages={systemMessages} removeMessageCallback={removeSystemMessage} />
      {currentUser.selectedClient.state === SelectClientState.PENDING && (
        <LoadingIndicator type={LoaderTypeEnums.PROGRESS} />
      )} */}
    </StyledDashboardLayout>
  );
};
