import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    gridArea: "dashboard",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10px 10px 0 10px",
    [theme.breakpoints.up("md")]: {
      padding: "25px 25px 0 25px",
    },
  };
});
