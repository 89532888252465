import * as yup from "yup";
import { TFunction } from "i18next";
import TConfig from "../../config";

export const DataImportSchema = (t: TFunction) => {
  return yup.object({
    suSa: yup.object().shape({
      dateText: yup.object().when("file", {
        is: (value: File | null) => {
          return !!value?.name;
        },
        then: () => yup.string().required(t("misc.required_field")!),
        otherwise: () => yup.string(),
      }),
      file: yup
        .mixed()
        .nullable()
        .test({
          name: "maxSize",
          test(value: unknown, ctx) {
            if (!value) {
              return true;
            }

            const file: File | null = value as File | null;
            if (!!file && file.name) {
              const max_size = TConfig.webapp.MAX_ATTACHMENT_SIZE;
              if (file.size > max_size) {
                return ctx.createError({
                  message: t("company/common:daten_import.warning_attachment_size", {
                    max_size: TConfig.webapp.MAX_ATTACHMENT_SIZE / 1000000,
                  })!,
                });
              } else {
                return true;
              }
            }
            return true;
          },
        }),
    }),
  });
};
