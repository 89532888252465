import { ProcessSteps } from "../elements/frontend/src/Store/api";

export const getNextStep = (currentStep: ProcessSteps, isAdvisorClient: boolean): ProcessSteps => {
  switch (currentStep) {
    case ProcessSteps.ONBOARDING:
      return ProcessSteps.DATEN_IMPORT;
    case ProcessSteps.DATEN_IMPORT:
      return isAdvisorClient ? ProcessSteps.CLIENT_AGREEMENT : ProcessSteps.PERSONAL_TALK;
    case ProcessSteps.PERSONAL_TALK:
      return ProcessSteps.TRANSACTION_CHECK;
    case ProcessSteps.TRANSACTION_CHECK:
      return ProcessSteps.CLIENT_AGREEMENT;
    case ProcessSteps.CLIENT_AGREEMENT:
      return ProcessSteps.DETAILS;
  }
  return ProcessSteps.ONBOARDING;
};

export const getPredictedNextStep = (completedSteps: ProcessSteps[]): ProcessSteps => {
  const lastCompletedStep =
    completedSteps.length > 0 ? completedSteps[completedSteps.length - 1] : null;

  switch (lastCompletedStep) {
    case ProcessSteps.ONBOARDING:
      return ProcessSteps.DATEN_IMPORT;
    case ProcessSteps.DATEN_IMPORT:
      return ProcessSteps.PERSONAL_TALK;
    case ProcessSteps.PERSONAL_TALK:
      return ProcessSteps.TRANSACTION_CHECK;
    case ProcessSteps.TRANSACTION_CHECK:
      return ProcessSteps.CLIENT_AGREEMENT;
    case ProcessSteps.CLIENT_AGREEMENT:
      return ProcessSteps.DETAILS;
    default:
      return ProcessSteps.ONBOARDING;
  }
};

export const addCompletedStep = (
  completedSteps: ProcessSteps[],
  newStep: ProcessSteps
): ProcessSteps[] => {
  if (!completedSteps.includes(newStep)) {
    return [...completedSteps, newStep];
  } else {
    return completedSteps;
  }
};

export const isAdvisorClient = (currentUser: any) => !!currentUser.user?.client_id;
