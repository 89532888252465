import { useMemo } from "react";
import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TConfig from "../../../../../config";
import { useParams } from "react-router-dom";
import { s3Upload } from "./awsInvestmentTeaser";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { useEffect, useState } from "react";

// Custom hook to handle file attributes
const useFileAttr = (transactionId) => {
  return useMemo(() => {
    if (!transactionId) return undefined;
    return [
      {
        urn: TConfig.record.URN.TRANSACTION,
        value: transactionId,
      },
    ];
  }, [transactionId]);
};

export const InvestmentTeaser = ({ disabled }) => {
  const { t } = useTranslation(["platform/common"]);
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const { transactionId } = useParams();
  const FILE_ATTR = useFileAttr(transactionId);
  const fileUrn = "invest_teaser";
  const dispatch = useDispatch();
  const [disableUpload, setDisableUpload] = useState(false);

  useEffect(
    () => {
      const productId = currentUser.customer.subscription.product_id;
      if (productId === "advisor" || "company") {
        setDisableUpload(true);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleUpload = async (file) => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      await s3Upload(fileUrn, file, FILE_ATTR);
      dispatch(setCurrentUser({ loading: false }));
      window.location.reload();
    } catch (error) {}
  };

  const handlePreview = async () => {
    const fileToOpen = `${window.location.origin}/investment-teaser/${transactionId}`;
    window.open(fileToOpen, "_blank", "");
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {!disableUpload && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="file"
            id="file"
            onChange={(e) => handleUpload(e.target.files[0])}
            style={{ display: "none" }}
          />
          <label htmlFor="file">
           { <Button
              color="secondary"
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon sx={{ height: "25px" }} />}
              sx={{
                px: 4,
                fontWeight: "bold",
                marginTop: "30px !important",
                margin: "20px 20px",
              }}
            >
              {t("platform/common:content.investor_matching.action.investment_teaser_upload")}{" "}
            </Button>}
          </label>
        </div>
      )}

      <Button
        color="secondary"
        disabled={disabled}
        onClick={handlePreview}
        variant="contained"
        component="span"
        startIcon={<VisibilityIcon sx={{ height: "25px" }} />}
        sx={{
          px: 4,
          fontWeight: "bold",
          marginTop: "30px !important",
          margin: "20px 20px",
        }}
      >
        {t("platform/common:content.investor_matching.action.show_investment_teaser")}{" "}
      </Button>
    </div>
  );
};
