import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../elements/frontend/src/Store/hooks/useAppSelector";
import { DataImportFormValues, FinanceFormValues } from "./interfaces";
import { LoadingIndicator } from "../../elements/frontend/src/components";
import { SuSaUpload } from "./components/SuSaUpload";
import { FormProvider, useForm } from "react-hook-form";
import { DashbordContext } from "../../views/Private/Home";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../elements/frontend/src/Store/hooks/useAppDispatch";
import TConfig from "../../config";
import {
  ProcessSteps,
  MetadataType,
  Ownership,
  SusaMetadata,
  Transaction,
} from "../../elements/frontend/src/Store/api";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { DataImportSchema } from "./DataImport.schema";
import { s3Upload } from "../../elements/frontend/src/common";
import { useUpdateTransactionMutation } from "../../elements/frontend/src/Store/api/transactions/transactionsApi";
import { useUpdateClientMutation } from "../../elements/frontend/src/Store/api/clients/clientsApi";
import { addCompletedStep, getNextStep, isAdvisorClient } from "../../utils/step.utils";
import { AdditionalCompanyInformation } from "../../elements/frontend/src/components/AdditionalCompanyInformation/AdditionalCompanyInformation";
import { Datahooks } from "./Datahook";

const getInitialFormValues = (context: any): DataImportFormValues => {
  const {
    finance: {
      sales_last_year,
      sales_prev_year,
      ebitda_prev_year,
      ebitda_last_year,
      ebit_prev_year,
      ebit_last_year,
      ownerships,
      number_employees_prev_year,
      number_employees_last_year,
    },
    susa: { date, dateText, file },
  } = context;

  return {
    finance: {
      sales_last_year,
      sales_prev_year,
      ebitda_prev_year,
      ebitda_last_year,
      ebit_prev_year,
      ebit_last_year,
      number_employees_prev_year,
      number_employees_last_year,
      ownerships,
    },
    suSa: {
      date,
      dateText,
      file,
      suSaUploaded: false,
    },
  };
};

export const DataImport = () => {
  const { t } = useTranslation(["company/common"]);
  const dispatch = useAppDispatch();
  const dashboardContext = useContext(DashbordContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const [updateTransaction] = useUpdateTransactionMutation();
  const [updateClient] = useUpdateClientMutation();

  const methods = useForm<DataImportFormValues>({
    mode: "all",
    defaultValues: getInitialFormValues(dashboardContext),
    resolver: yupResolver(DataImportSchema(t)),
  });

  const { handleSubmit, watch, setValue } = methods;

  const { detailsCompany } = Datahooks(currentUser);

  const susaData: SusaMetadata = {
    scope: "susa",
    data: {
      sales_prev_year: watch("finance.sales_prev_year"),
      sales_last_year: watch("finance.sales_last_year"),
      ebit_prev_year: watch("finance.ebit_prev_year"),
      ebit_last_year: watch("finance.ebit_last_year"),
      ebitda_prev_year: watch("finance.ebitda_prev_year"),
      ebitda_last_year: watch("finance.ebitda_last_year"),
      number_employees_prev_year: watch("finance.number_employees_prev_year"),
      number_employees_last_year: watch("finance.number_employees_last_year"),
      ownerships: watch("finance.ownerships"),
    },
  };
  const transactionId = (dashboardContext.transactions[0] as Transaction | undefined)
    ?.transaction_id;
  const clientId = currentUser.selectedClient.client_id;
  const dateText = watch("suSa.dateText");
  const file_attr = useMemo(() => {
    return [
      {
        urn: TConfig.record.URN.TRANSACTION,
        value: transactionId,
      },
      {
        urn: TConfig.file.ATTRIB.SUSA_DATE,
        value: dateText,
      },
    ];
  }, [dateText, transactionId]);

  const handleSkip = async () => {
    dispatch(
      setCurrentUser({
        loading: true,
      })
    );
    const nextStep = getNextStep(ProcessSteps.DATEN_IMPORT, isAdvisorClient(currentUser));
    const metadata: MetadataType[] = [
      {
        scope: "profile",
        data: {
          dashboard_currentStep: nextStep ? nextStep : ProcessSteps.DATEN_IMPORT,
          dashboard_lastStep: ProcessSteps.DATEN_IMPORT,
          dashboard_completedSteps: addCompletedStep(
            dashboardContext.dashboard_completedSteps,
            ProcessSteps.DATEN_IMPORT
          ),
        },
      },
    ];

    if (clientId) {
      await updateClient({ clientId, body: { metadata } });
    }

    window.location.reload();
  };

  const handleStep = async (values: DataImportFormValues) => {
    const {
      suSa: { file },
      finance,
    } = values;

    dispatch(
      setCurrentUser({
        loading: true,
      })
    );

    if (file) {
      await s3Upload(TConfig.file.URN.SUSA, file, file_attr);

      const updatedTransactionData: Partial<Transaction> = {
        metadata: [{ scope: "state", data: { susa: true } }],
      };

      if (transactionId) {
        await updateTransaction({ transactionId, body: updatedTransactionData });
      }
    }

    const {
      sales_prev_year,
      sales_last_year,
      ebitda_prev_year,
      ebitda_last_year,
      ebit_prev_year,
      ebit_last_year,
      number_employees_prev_year,
      number_employees_last_year,
      ownerships,
    } = finance;

    const nextStep = getNextStep(ProcessSteps.DATEN_IMPORT, isAdvisorClient(currentUser));
    const metadata: MetadataType[] = [
      {
        scope: "profile",
        data: {
          dashboard_currentStep: nextStep ? nextStep : ProcessSteps.DATEN_IMPORT,
          dashboard_lastStep: ProcessSteps.DATEN_IMPORT,
          dashboard_completedSteps: addCompletedStep(
            dashboardContext.dashboard_completedSteps,
            ProcessSteps.DATEN_IMPORT
          ),
        },
      },
      {
        scope: "susa",
        data: {
          sales_prev_year,
          sales_last_year,
          ebitda_prev_year,
          ebitda_last_year,
          ebit_prev_year,
          ebit_last_year,
          number_employees_prev_year,
          number_employees_last_year,
          ownerships,
        },
      },
    ];

    if (clientId) {
      await updateClient({ clientId, body: { metadata } });
    }

    dispatch(
      setCurrentUser({
        loading: false,
      })
    );

    window.location.reload();
  };

  const handleSusaDataChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(`finance.${event.target.name as keyof FinanceFormValues}`, event.target.value);
  };

  const handleOwnershipChange = (newOwnerships: Ownership[]) => {
    setValue("finance.ownerships", newOwnerships);
  };

  return (
    <>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
      <Box component="form" sx={{ width: "100%", maxWidth: theme.breakpoints.values.xl }}>
        <FormProvider {...methods}>
          <SuSaUpload />
          <AdditionalCompanyInformation
            susa={susaData}
            handleOwnershipChange={handleOwnershipChange}
            handleSusaDataChange={handleSusaDataChange}
            detailsCompany={detailsCompany}
          />

          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              size={isMobile ? "small" : "medium"}
              sx={{
                px: 3,
                py: 1,
                mr: 3,
                mb: 5,
                mt: 2,
                fontWeight: "bold",
                color: theme.palette.grey[500],
                borderRadius: "4px",
                backgroundColor: theme.palette.common.white,
                "&:hover": {
                  borderColor: theme.palette.grey[500],
                },
              }}
              onClick={handleSkip}
            >
              {t("misc.skip")}
            </Button>
            <Button
              onClick={handleSubmit(handleStep)}
              variant="contained"
              color="secondary"
              size={isMobile ? "small" : "medium"}
              sx={{
                px: { xs: 3, md: 6 },
                py: 1,
                mr: 1,
                mb: 5,
                mt: 2,
                fontWeight: "bold",
                color: theme.palette.primary.main,
                borderRadius: "4px",
              }}
            >
              {t("misc.save")}
            </Button>
          </Stack>
        </FormProvider>
      </Box>
    </>
  );
};
