import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { LoadingIndicator } from "../../../elements/frontend/src/components";
import { clearCurrentUser } from "../../../elements/frontend/src/Store/currentUser/currentUserSlice";

const Logout = () => {
  const [loggingOut, setLoggingOut] = useState(true);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    Auth.signOut().then(() => {
      setLoggingOut(false);
      dispatch(clearCurrentUser({}));
    });
  }, [currentUser, dispatch]);

  return loggingOut ? <LoadingIndicator type={"PAGE"} /> : null;
};

export default Logout;
