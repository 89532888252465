import { Product, Customers } from "../../Store/api";

export const initialProductsData: Product[] = [
  { product_name: "", revenue_type: "", sales_volume: 0 },
  { product_name: "", revenue_type: "", sales_volume: 0 },
  { product_name: "", revenue_type: "", sales_volume: 0 },
];

export const initialCustomersData: Customers[] = [
  { customer_name: "", big_volume: 0, revenue_volume: 0 },
  { customer_name: "", big_volume: 0, revenue_volume: 0 },
  { customer_name: "", big_volume: 0, revenue_volume: 0 },
];
