import { Stack } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { PopupSchema } from "./PopupSchema";
import { yupResolver } from "@hookform/resolvers/yup";

interface PopupFormProps {
  handleClose: () => void;
  fetchDataAndDetails: (trade_registration: string, postal: string) => void;
}

export const PopupForm = ({ handleClose, fetchDataAndDetails }: PopupFormProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const { t } = useTranslation(["company/common"]);
  const methods = useForm({ mode: "all", resolver: yupResolver(PopupSchema(t)) });
  const { control, setValue } = methods;

  useEffect(() => {
    setValue("trade_registration", "HRB-");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <FormProvider {...methods}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mb: 2 }}>
          <Controller
            control={control}
            name="trade_registration"
            render={({ fieldState: { error }, field: { value, onChange } }) => (
              <TextField
                name="trade_registration"
                label={t("company/common:onboarding.profile.trade_registration")}
                value={value}
                size="small"
                required
                fullWidth
                onChange={onChange}
                error={!!error?.message}
                helperText={error?.message}
                inputProps={{
                  autoComplete: "new-trade_registration",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="postal"
            render={({ fieldState: { error }, field: { value, onChange } }) => (
              <TextField
                name="postal"
                label={t("company/common:onboarding.company.postal_code")}
                value={value}
                size="small"
                required
                fullWidth
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, "");
                  onChange(numericValue);
                }}
                error={!!error?.message}
                helperText={error?.message}
                inputProps={{
                  autoComplete: "new-postal",
                  form: {
                    autoComplete: "off",
                  },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            )}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size={isMobile ? "small" : "medium"}
            sx={{
              px: { xs: 3, md: 6 },
              py: 1,
              mr: 1,
              mb: 5,
              mt: 2,
              fontWeight: "bold",
              color: theme.palette.primary.main,
              borderRadius: "4px",
            }}
            onClick={() => {
              fetchDataAndDetails(
                control._formValues.trade_registration,
                control._formValues.postal
              );
              handleClose();
            }}
          >
            {t("misc.update")}
          </Button>
        </Stack>
      </FormProvider>
    </div>
  );
};
