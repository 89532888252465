import { InvestStructure } from "../../elements/frontend/src/Store/api";

export enum OnboardingSteps {
  COLLECT_DATA,
  DOWNLOAD_DOCUMENTS,
}

export interface OnboardingFormValues {
  currentStep: OnboardingSteps;
  company_name: string;
  street: string;
  nace_code: string[];
  postal: string;
  city: string;
  country: string;
  first_name: string;
  last_name: string;
  telephone: string;
  legal_form: string;
  fiscal_year: string;
  trade_registration: string;
  website: string;
  categories: string[];
  subCategories: string[];
  min_cap: string;
  max_cap: string;
  phase: string;
  self_ev: string;
  stake: string[];
  type: string[];
  structure: InvestStructure;
  period: string;
  no_bankruptcy: boolean;
  no_project_business: boolean;
  avv_received: boolean;
  nda_received: boolean;
}
