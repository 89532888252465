import { expirableLocalStorage } from "expirable-storage";
import React, { useEffect } from "react";

export const DocuSignAuthCompletePage = () => {
  useEffect(() => {
    const params = getHashParams();
    if (params.access_token) {
      expirableLocalStorage.setItem(
        "docuSignAccessToken",
        params.access_token,
        parseInt(params.expires_in) - 60
      );
    }
  }, []);

  return <div>Authentifizierung abgeschlossen. Sie können diesen Bildschirm wieder schließen.</div>;
};

const getHashParams = () => {
  var hash = window.location.hash.substr(1);
  var result = hash.split("&").reduce(function (res, item) {
    var parts = item.split("=");
    res[parts[0]] = parts[1];
    return res;
  }, {});

  return result;
};
