import * as yup from "yup";
import { TFunction } from "i18next";

export const PopupSchema = (t: TFunction) => {
  return yup.object().shape({
    company_name: yup.string().required(t("misc.required_field") || ""),
    trade_registration: yup
      .string()
      .required(t("misc.required_field") || "")
      .matches(/^HR(A|B)?-[0-9]+$/i, t("error.wrong_trade_registration_format") || ""),
    postal: yup
      .string()
      .required(t("misc.required_field") || "")
      .matches(/^[0-9]+$/i, t("error.invalid_postal_code") || ""),
  });
};
