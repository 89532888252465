import { Controller, useFormContext } from "react-hook-form";
import { DataImportFormValues } from "../interfaces";
import { Alert, Card, FadeIn, Section } from "../../../elements/frontend/src/components";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import i18n from "i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import Typography from "@mui/material/Typography";
import React, { ChangeEvent } from "react";
import { useTheme } from "@mui/material/styles";
import { dateObjectConverter } from "../../../elements/frontend/src/common";

export const SuSaUpload = () => {
  const theme = useTheme();
  const { t } = useTranslation(["company/common"]);
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<DataImportFormValues>();
  const file = watch("suSa.file");
  const dateTextError = errors.suSa?.dateText;
  const fileError = errors.suSa?.file;
  const maxSizeError = fileError?.type === "maxSize";

  const handleDatePickerChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setValue("suSa.date", date);
      setValue("suSa.dateText", dateObjectConverter(date), { shouldValidate: true });
    }
  };

  const handleSuSaFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileObj = e.target?.files?.item(0);

    if (fileObj) {
      setValue("suSa.file", fileObj, { shouldValidate: true });}
  };

  return (
    <>
      {/* @ts-ignore */}
      <Section title={t("company/common:daten_import.headline")}>
        <Card>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            <Stack width={{ xs: "100%", md: "50%" }}>
            <Controller
              name={"suSa.date"}
              control={control}
              render={({ field: { value }, fieldState: { error } }) => (
                <div>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                  <DatePicker
                    disableFuture
                    views={["year", "month"]}
                    openTo="month"
                    label={t("company/common:daten_import.susa_date")}
                    minDate={dayjs(`${dayjs().year() - 1}-01-01`)} //from last year, 1st of January
                    maxDate={dayjs(`${dayjs().year()}-${dayjs().month()}`)} // upto last month, including last month
                    value={value ? value : null}
                    onChange={(newDate) => {
                      handleDatePickerChange(newDate);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!dateTextError?.message}
                        helperText={dateTextError?.message}
                        fullWidth
                        sx={{
                          svg: { color: theme.palette.primary.main },
                        }}
                        inputProps={{ ...params.inputProps, readOnly : true }}
                      />
                    )}
                  />
                </LocalizationProvider>
                </div>
              )}
            />
          </Stack>
          <Stack width={{ xs: "100%", md: "50%" }}>
          <Controller
            name="suSa.file"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <div>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={t("company/common:daten_import.susa_upload")}
                  value={value ? value.name : ""}
                  onBlur={onBlur}
                  sx={{
                    "& label": { p: 0 },
                  }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Tooltip title={t("company/common:daten_import.susa_upload")} arrow>
                        <IconButton aria-label="upload" component="label">
                          <FolderCopyOutlinedIcon fontSize="medium" color="primary" />
                          <input hidden type="file" onChange={handleSuSaFileChange} accept=".xls, .pdf" />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                  error={!!error}
                  helperText={error ? t("company/common:daten_import.susa_upload_error") : ""}
                />
                {/* Add a conditional warning message */}
                {file && file.name && !file.name.match(/\.(xls|pdf)$/i) && (
                  <Typography variant="caption" color="error">
                    {t("company/common:daten_import.susa_upload_error")}
                  </Typography>
                )}
              </div>
            )}
          />
          </Stack>
          </Stack>
          <Stack sx={{ mt: 2 }}>
            <Typography
              variant="subtitle1"
              color="primary"
              sx={{ fontSize: "15px", display: "inline-block", textAlign: "justify" }}
            >
              <Typography
                variant="body1"
                color="error"
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  display: "inline-block",
                  marginRight: "10px",
                }}
              >
                {t("company/common:daten_import.info")}
              </Typography>
              {t("company/common:daten_import.info_text")}
            </Typography>
          </Stack>
          {maxSizeError && (
            <Stack sx={{ mt: 4 }}>
              <FadeIn>
                <Alert type="ERROR">{fileError.message}</Alert>
              </FadeIn>
            </Stack>
          )}
        </Card>
      </Section>
    </>
  );
};
