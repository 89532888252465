import React, { useEffect, useState, useMemo } from "react";
import { Box } from "@mui/material";
import { initialDDValues } from "./interfaces";
import { useTheme } from "@mui/material/styles";
import DDAccordion from "./DDAccordion";
import { useParams } from "react-router-dom";
import { useTransactions } from "../../../hooks";
import { useGetTransactionByIdQuery } from "../../../Store/api/transactions/transactionsApi";
import { LoadingIndicator } from "../../LoadingIndicator";
import { useAppSelector } from "../../../Store/hooks/useAppSelector";
import { Notifications } from "./Notifications";
import { setCurrentUser } from "../../../Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../../Store/hooks/useAppDispatch";

const useNotifications = (ddFiles) => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const productId = currentUser?.customer?.subscription?.product_id;
  const [notificationsList, setNotificationsList] = useState([]);

  const userId = useMemo(() => {
    return (
      (productId === "platform" ? currentUser?.user?.user_id : currentUser?.attributes?.user_id) ||
      ""
    );
  }, [currentUser, productId]);

  const isFileNotViewed = (file) => {
    return file?.metadata?.[0]?.data?.some(
      (entry) => entry && entry.viewedBy && !entry.viewedBy.includes(userId)
    );
  };
  useEffect(() => {
    if (ddFiles) {
      ddFiles.map((file) => {
        if (isFileNotViewed(file)) {
          const notification = {
            section: file.dd_section,
            name: file.file_name,
            id: file.file_id,
          };

          if (!notificationsList.some((existing) => existing.id === notification.id)) {
            setNotificationsList((prev) => [...prev, notification]);
          }
        }
        return null;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ddFiles]);

  return { notificationsList, productId };
};

export const DDForm = () => {
  const theme = useTheme();
  const { updateTransaction, deleteFileById } = useTransactions();
  const params = useParams();
  const transactionId = params.transactionId;
  const transactionByIdQuery = useGetTransactionByIdQuery({ transactionId });

  const metaData1 = transactionByIdQuery?.data?.metadata.find((item) => item.scope === "dd_legal")
    ?.data?.sections;

  const [ddState, setDDState] = useState(false);
  const [expanded, setExpanded] = useState("0");
  const [initialFetch, setInitialFetch] = useState(true);
  const [ddFiles, setDDFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { notificationsList, productId } = useNotifications(ddFiles);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ddState && loading) {
      setLoading(false);
    }
  }, [ddState, loading]);

  useEffect(() => {
    if (transactionByIdQuery?.data) {
      const filteredFiles = transactionByIdQuery?.data?.files?.filter(
        (file) => file.file_urn === "dd_legal"
      );
      transactionByIdQuery?.data?.files?.forEach((file) => {});
      setDDFiles(filteredFiles);
      const metaData = transactionByIdQuery?.data?.metadata?.find(
        (item) => item.scope === "dd_legal"
      )?.data?.sections;

      if (metaData && initialFetch) {
        setDDState(metaData);
        setInitialFetch(false);
      } else if (initialFetch) {
        setDDState(initialDDValues);
        setInitialFetch(false);
      }
    }
  }, [transactionByIdQuery, initialFetch]);

  useEffect(() => {
    if (!ddFiles) return;
    for (const file of ddFiles) {
      if (file.file_urn === "dd_legal" && !file.dd_section) {
        deleteFileById(file.file_id);
      }
    }
  }, [ddFiles, deleteFileById]);

  const transactionUpdate = async (newDDState) => {
    if (!newDDState) return;
    try {
      const objToSave = {
        metadata: [
          {
            scope: "dd_legal",
            data: {
              sections: newDDState,
            },
          },
        ],
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await updateTransaction(transactionId, objToSave);
    } catch (e) {}
  };

  const handleChange = async (index, subIndex, field, value) => {
    const newDDState = {
      ...ddState,
      [index]: {
        ...ddState[index],
        [subIndex]: {
          ...ddState[index][subIndex],
          [field]:
            value === "on"
              ? true
              : value === "off"
              ? false
              : field === "files" || field === "comments"
              ? Array.isArray(value)
                ? value
                : [...ddState[index][subIndex][field], value]
              : value,
        },
      },
    };
    setDDState(newDDState);
    dispatch(setCurrentUser({ loading: true }));
    await transactionUpdate(newDDState);
    dispatch(setCurrentUser({ loading: false }));
  };

  return (
    <>
      {loading && <LoadingIndicator type={"COMPONENT"} />}
      {!loading && (
        <Box
          component="form"
          sx={{
            width: "100%",
            maxWidth: theme.breakpoints.values.xl,
            margin: "auto",
            padding: "0px",
          }}
        >
          {productId !== "company" && (
            <Notifications transactionId={transactionId} notificationsList={notificationsList} />
          )}
          {Object.keys(ddState).map((keyName) => {
            return (
              <DDAccordion
                key={keyName.replaceAll(".", "-")}
                name={keyName}
                data={ddState[keyName]}
                handleChange={handleChange}
                expanded={expanded}
                setExpanded={setExpanded}
                setDDState={setDDState}
                ddState={ddState}
                metaData1={metaData1}
                ddFiles={ddFiles}
              />
            );
          })}
        </Box>
      )}
    </>
  );
};
