import { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { lazy } from "@loadable/component";
import { useSelector } from "react-redux";
import { InvestmetTeaserView } from "../../elements/frontend/src/components/InvestorTable/InvestmetTeaserView";

import ErrorPage from "./ErrorPage";
import { LoadingIndicator } from "../../elements/frontend/src/components";

const Login = lazy(() => import("./Login"));
const ResetPassword = lazy(() => import("../../elements/frontend/src/components/ResetPassword"));
const ForgotPassword = lazy(() => import("../../elements/frontend/src/components/ForgotPassword"));
const Register = lazy(() => import("./Register"));
const ChangePassword = lazy(() => import("../../elements/frontend/src/components/ChangePassword"));

const Public = () => {
  const currentUser = useSelector(({ currentUser }) => currentUser);

  return (
    <Routes>
      <Route exact path="/investment-teaser/:transactionId" element={<InvestmetTeaserView />} />
      <Route
        path="/"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <>{currentUser.forceChange ? <ChangePassword /> : <Login />}</>
          </Suspense>
        }
      />
      <Route path="logout" element={<Navigate to="/login" />} />
      <Route
        exact
        path="login"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <>{currentUser.forceChange ? <ChangePassword /> : <Login />}</>
          </Suspense>
        }
      />
      <Route
        path="reset"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <>{currentUser.authenticated ? <Navigate to="/dashboard" /> : <ResetPassword />}</>
          </Suspense>
        }
      />
      <Route
        path="forgot"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <ForgotPassword />
          </Suspense>
        }
      />
      <Route
        path="register"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <Register />
          </Suspense>
        }
      />
      <Route
        path="register-success"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <Register />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingIndicator type={"PAGE"} />}>
            <ErrorPage
              title="404 - NOT FOUND!"
              message="The page you are looking for is not found!"
            />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Public;

/**
 ** --- PUBLIC ---
 * HOME = "/"
 * RESET = "/reset"
 * LOGIN = "/login"
 * FORGOT = "/forgot"
 * REGISTER = "/register"
 * REGISTER_SUCCESS = "/register-success"
 * CHANGE_PASSWORD = "/change-password"
 *
 ** --- PRIVATE ---
 * LOGOUT = "/logout"
 * DASHBOARD = "/dashboard" ??????
 */
