import React, { useCallback } from "react";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import {
  useDocuSignAccessToken,
  createAndSendEnvelope,
  useEnvelopeStatus,
  getSignedDocument,
} from "../../api/docuSign";

type DocumentSignDialogProps = {
  isOpen: boolean;
  fileUrl: string | null;
  envelopeId: string | null;
  header: string;
  emails: string[];
  isFinalized: boolean;
  onClose: () => void;
  onSign: () => void;
  onAllSigned: (doc: any) => Promise<void>;
  onSendEnvelope: (envelopeId: string) => Promise<void>;
};

export const DocumentSignDialog = ({
  isOpen,
  fileUrl,
  envelopeId,
  header,
  emails,
  isFinalized,
  onAllSigned,
  onClose,
  onSendEnvelope,
}: DocumentSignDialogProps) => {
  const theme = useTheme();
  const [accessToken, accountId] = useDocuSignAccessToken();
  // @ts-ignore
  const envelopeStatus: any[] = useEnvelopeStatus(accessToken, accountId, envelopeId);
  const [loading, setLoading] = React.useState(false);

  const allSigned = envelopeStatus?.every((status) => status.status === "completed");

  const onSendDocuments = useCallback(async () => {
    const id = await createAndSendEnvelope(accessToken, accountId, emails, fileUrl);
    await onSendEnvelope(id);
  }, [accessToken, fileUrl, onSendEnvelope, accountId, emails]);

  const onAllDocumentsSigned = useCallback(async () => {
    setLoading(true);
    const doc = await getSignedDocument(accessToken, accountId, envelopeId);
    await onAllSigned(doc);
    setLoading(false);
  }, [accessToken, envelopeId, onAllSigned, accountId]);

  if(!accessToken) {
    return (
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "70%",
              maxWidth: "400px",
            },
          },
        }}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <Typography variant="inherit" style={{ fontSize: 24, fontWeight: "bold" }}>
            {header}
          </Typography>
          <Stack></Stack>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="inherit"
            style={{ fontSize: 16, fontWeight: "bold", textAlign: "center", color: "red" }}
          >
            Erfordert ein Docusign-Konto
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "70%",
            maxWidth: "400px",
          },
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <Typography variant="inherit" style={{ fontSize: 24, fontWeight: "bold" }}>
          {header}
        </Typography>
        <Stack></Stack>
      </DialogTitle>
      <DialogContent>
        {envelopeStatus && (
          <>
            <Typography
              variant="inherit"
              style={{ fontSize: 16, fontWeight: "bold", marginBottom: "16px" }}
            >
              E-Mails
            </Typography>
            <Stack display="flex" flexDirection="column">
              <Stack display="flex" flexDirection="column" gap="8px">
                {envelopeStatus.map((status) => {
                  let displayStatus = status.status;
                  if(displayStatus === "completed") {
                    displayStatus = "vollendet";
                  }
                  else if(displayStatus === "sent") {
                    displayStatus = "gesendet";
                  }

                  return (
                    <Stack
                      display="flex"
                      flexDirection="row"
                      gap={2}
                      flexWrap="nowrap"
                      justifyContent={"space-between"}
                    >
                      <Typography variant="inherit" style={{ fontSize: 16, maxWidth: "200px" }}>
                        {status.email}
                      </Typography>
                      <Stack
                        style={{
                          width: "80px",
                          background: status.status === "completed" ? "#18d2a3" : "#F0F0F0",
                          padding: "4px",
                          alignItems: "center",
                          borderRadius: "16px",
                        }}
                      >
                        <Typography variant="inherit" style={{ fontSize: 12 }}>
                          {displayStatus}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </>
        )}
      </DialogContent>
      {envelopeId !== undefined && isFinalized && (
        <Typography
          variant="inherit"
          style={{ fontSize: 16, fontWeight: "bold", color: "green", textAlign: "center" }}
        >
          {"Dokumente sind unterschrieben, wir werden Sie bald kontaktieren."}
        </Typography>
      )}
      <DialogActions
        style={{
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          gap: "8px",
        }}
      >
        {accessToken && !envelopeId && (
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            // size={isMobile ? 'small' : 'medium'}
            sx={{
              px: { sx: 2, md: 4 },
              fontWeight: "bold",
              color: theme.palette.secondary.main,
              width: "100%",
            }}
            onClick={async () => {
              setLoading(true);
              await onSendDocuments();
              setLoading(false);
            }}
          >
            {"Doukment an Gesellschafter senden"}
          </Button>
        )}
        {accessToken && envelopeId !== undefined && !isFinalized && (
          <Button
            variant="contained"
            color="primary"
            disabled={!allSigned || loading}
            // size={isMobile ? 'small' : 'medium'}
            sx={{
              fontWeight: "bold",
              color: theme.palette.secondary.main,
              width: "100%",
            }}
            onClick={onAllDocumentsSigned}
          >
            {"Dokument fertigstellen"}
          </Button>
        )}
        {accessToken && envelopeId !== undefined && (
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            // size={isMobile ? 'small' : 'medium'}
            sx={{
              fontWeight: "bold",
              color: theme.palette.secondary.main,
              width: "100%",
            }}
            onClick={async () => {
              if (
                window.confirm(
                  "Durch diese Aktion wird ein neues Dokument erstellt und erneut an alle Gesellschafter gesendet. Alle Gesellschafter müssen das Dokument erneut unterzeichnen. Bist du sicher?"
                )
              ) {
                setLoading(true);
                await onSendDocuments();
                setLoading(false);
              }
            }}
          >
            {"Erneut senden"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};