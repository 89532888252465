import * as yup from "yup";
import parsePhoneNumber from "libphonenumber-js";
import { TFunction } from "i18next";
import { OnboardingFormValues, OnboardingSteps } from "./interfaces";

export const OnboardingSchema = (t: TFunction) => {
  //  const phoneMsg = t("misc.required_field");
  return yup.object({
    company_name: yup.string().required(t("misc.required_field")!),
    trade_registration: yup.object<OnboardingFormValues>().when("country", {
      is: (value: string) => {
        return value === "DEU";
      },
      then: () =>
        yup
          .string()
          .required(t("misc.required_field")!)
          .matches(new RegExp("^HR(A|B)?-[0-9]+$", "gi"), {
            message: t("error.wrong_trade_registration_format"),
          }),
      otherwise: () => yup.string().required(t("misc.required_field")!),
    }),
    street: yup.string().required(t("misc.required_field")!),
    postal: yup
      .string()
      .required(t("misc.required_field")!)
      .matches(new RegExp("^[0-9]+$", "gi"), {
        message: t("error.invalid_postal_code"),
      }),
    city: yup.string().required(t("misc.required_field")!),
    country: yup.string().required(t("misc.required_field")!),
    // website: yup.string().test("is-valid-url", "Invalid URL", function (value) {
    //   if (value && value.length > 0) {
    //     const urlPattern = new RegExp(
    //       "^(www\\.[a-z\\d]([a-z\\d-]*[a-z\\d]*)\\.(com|de|net|org|eu)(\\.([a-z]{2}))?|http://([a-z\\d]([a-z\\d-]*[a-z\\d]*)\\.(com|de|net|org|eu)(\\.([a-z]{2}))?))$",
    //       "i"
    //     );
    //     if (!urlPattern.test(value)) {
    //       return false;
    //     }
    //   }
    //   return true;
    // }),
    first_name: yup.string().required(t("misc.required_field")!),
    last_name: yup.string().required(t("misc.required_field")!),
    //    telephone: yup.string().required(t("misc.required_field")!).min(5, phoneMsg),
    telephone: yup
      .string()
      .required(t("misc.required_field")!)
      .test({
        name: "isValid",
        test(value, ctx) {
          const phone = parsePhoneNumber(value);
          if (!phone?.isValid()) {
            return ctx.createError({ message: t("error.wrong_phone_number_format")! });
          } else {
            return true;
          }
        },
      }),
    legal_form: yup.string().required(t("misc.required_field")!),
    min_cap: yup.string().required(t("misc.required_field")!),
    max_cap: yup.string().required(t("misc.required_field")!),

    avv_received: yup.object<OnboardingFormValues>().when("currentStep", {
      is: (value: OnboardingSteps) => {
        return value === OnboardingSteps.DOWNLOAD_DOCUMENTS;
      },
      then: () => yup.boolean().oneOf([true], t("error.avv_not_downloaded")!),
      otherwise: () => yup.boolean(),
    }),
    nda_received: yup.object<OnboardingFormValues>().when("currentStep", {
      is: (value: OnboardingSteps) => {
        return value === OnboardingSteps.DOWNLOAD_DOCUMENTS;
      },
      then: () => yup.boolean().oneOf([true], t("error.nda_not_downloaded")!),
      otherwise: () => yup.boolean(),
    }),
  });
};
