import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PopupForm } from "./PopupForm";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "28%",
  bgcolor: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

interface OnboardinPopupProps {
  intialValue: any;
  fetchDataAndDetails: (trade_registration: string, postal: string) => void;
}

export default function OnboardinPopup({ intialValue, fetchDataAndDetails }: OnboardinPopupProps) {
  const { t } = useTranslation(["company/common"]);

  const [open, setOpen] = useState(
    intialValue._formValues.trade_registration && intialValue._formValues.postal ? false : true
  );

  const handleClose = () => setOpen(false);

  return (
    <div>
      {open && (
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
              <InfoOutlinedIcon sx={{ mr: 1 }} />
              <Typography variant="subtitle1">
                {t("company/common:onboarding.notification")}
              </Typography>
            </Box>
            <PopupForm handleClose={handleClose} fetchDataAndDetails={fetchDataAndDetails} />
          </Box>
        </Modal>
      )}
    </div>
  );
}
